import {Dialog, DialogContent, DialogTitle, Divider, IconButton, Typography} from "@mui/material";
import React from "react";
import {LocalSpinner} from "../Spinner";
import {Flex} from "../Layouts";
import {Typo} from "../Typography";
import {Document, Page, pdfjs} from "react-pdf";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import colors from "../../theme/colors";
import EastIcon from "@mui/icons-material/East";
import {useMainTranslation} from "../../hooks/useMainTranslationHooks/useMainTranslation";
import RemoveIcon from '@mui/icons-material/Remove';
import AddIcon from '@mui/icons-material/Add';
import DownloadIcon from '@mui/icons-material/Download';
import {usePdfViewer} from "./hooks/usePdfViewer";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

export const DocViewerDialog = () => {
    const {
        dialogCommon,
        zoom,
        file,
        pages
    } = usePdfViewer();
    const {revDir} = useMainTranslation();

    return(
        <Dialog
            open={dialogCommon.isOpen}
            onClose={dialogCommon.handleClose}
            aria-labelledby="max-width-dialog-title"
            fullScreen
            fullWidth
            sx={{'.MuiPaper-root': {width: '100%'}, overflow: 'hidden'}}
        >
            <DialogTitle sx={{padding: '0'}}>
                {/*TITLE*/}
                <Flex
                    ai={'center'}
                    box_shadow={'0px 0.6px 1.8px rgba(0, 0, 0, 0.1), 0px 3.2px 7.2px rgba(0, 0, 0, 0.13)'}
                    bb={`2px solid ${colors.primary.disable}`}
                    background={colors.backgrounds.grey_light}
                    p={'16px'}
                    jc={'space-between'}
                >
                    <Flex ai={'center'} gap={'32px'} maxw={'calc(100vw - 100px)'}>
                        {!revDir ?
                            <KeyboardBackspaceIcon sx={{color: colors.text.blue, cursor: 'pointer'}} onClick={dialogCommon.handleClose}/>
                            :
                            <EastIcon sx={{color: colors.text.blue, cursor: 'pointer'}} onClick={dialogCommon.handleClose}/>
                        }
                        <Typography variant={'h4'} color={colors.grayText} noWrap>{file.getDocumentName()}</Typography>
                    </Flex>

                    <IconButton disabled={file.isDisabledDownload} onClick={file.handleDownloadFile} size={'small'}>
                        <DownloadIcon />
                    </IconButton>
                </Flex>

                {/*ACTIONS*/}
                <Flex
                    ai={'center'}
                    jc={'center'}
                    background={colors.backgrounds.grey_dark}
                    css={`filter: drop-shadow(0px 0.6px 1.8px rgba(0, 0, 0, 0.1)) drop-shadow(0px 3.2px 7.2px rgba(0, 0, 0, 0.13))`}
                    p={'16px 0'}
                >
                    <Flex ai={'center'} gap={'11px'}>
                        <Typography fontSize={'14px'} fontWeight={600} color={colors.grayText}>{pages.currentPage}</Typography>
                        <Typography fontSize={'14px'} fontWeight={600} color={colors.text.grey_dark}>/ {pages.totalPages}</Typography>
                    </Flex>

                    <Divider flexItem orientation={'vertical'} sx={{margin: '0 24px'}}/>

                    <Flex ai={'center'} gap={'21px'}>
                        <IconButton size={'small'} sx={{padding: 0}} onClick={zoom.handleDecreaseZoom} disabled={zoom.isDisabledDecreaseZoom}>
                            <RemoveIcon />
                        </IconButton>

                        <Typography fontSize={'14px'} fontWeight={600} color={colors.grayText}>{zoom.zoomText}%</Typography>

                        <IconButton size={'small'} sx={{padding: 0}} onClick={zoom.handleIncreaseZoom}>
                            <AddIcon />
                        </IconButton>
                    </Flex>
                </Flex>
            </DialogTitle>

            <DialogContent sx={{padding: '0', backgroundColor: colors.backgrounds.blue_light_5}} onScroll={pages.onScroll} ref={pages.pagesRef}>
                {dialogCommon.isLoading && <LocalSpinner />}
                {file.isFileNotFound && <Typo fontSize={'16px'} fontWeight={400}>File not found!</Typo>}
                {file.isFileExists &&
                    <Flex
                        minw={'fit-content'}
                        jc={'center'}
                        // overflow={'auto'}
                        p={'76px 0 0 0'}
                    >
                        <Document
                            file={file.getDocumentFile()}
                            onLoadSuccess={file.onDocumentLoadSuccess}
                        >
                            {(pages.totalPages ?? 0) > 0 && new Array(pages.totalPages).fill(1).map((_, i) => (
                                <Flex m={'16px'} ai={'center'} jc={'center'} key={i+1} ref={(ref) => {pages.handleAppendRef(i+1, ref)}}>
                                    <Page pageNumber={i+1} scale={zoom.zoom}/>
                                </Flex>
                            ))}
                        </Document>
                    </Flex>
                }
            </DialogContent>
        </Dialog>
    )
}

export const TMP = () => null;