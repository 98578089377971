import React, {FC} from "react";
import {Menu, Divider, Typography} from "@mui/material";
import {useTopBar} from "../../hooks/useTopBar";
import {CommonAvatar} from "../../../../../newShared/components/Basic/CommonAvatar";
import {getName} from "../../../../../newShared/utils/text";
import {
    UseManageWorkspacesAndOrganizations
} from "../../../../authWorkspacesCookies/workspaces/hooks/useManageWorkspacesAndOrganizations";
import {useMainTranslation} from "../../../../../newShared/hooks/useMainTranslationHooks/useMainTranslation";
//import { SHOW_CHANGE_LANGUAGE_IN_TOPBAR} from "../../../../../newShared/constants";
import {ChangeLanguageDialog} from "../../../../../newShared/components/changeLanguageDialog/hook";
import {LanguageChangeDialog} from "../../../../../newShared/components/changeLanguageDialog";
import {AvatarMenuTitle} from "../avatarMenuTitle";
import {MenuItemHoverBlue} from "../styled";
//import {useHistory} from "react-router-dom";
import colors from "../../../../../newShared/theme/colors";

export const TopBarAvatar: FC = () => {
    const {t, revDir} = useMainTranslation('', {keyPrefix: 'TopBar.user'});
    //const history = useHistory();

    const {currData: {currentUser}, logout} = UseManageWorkspacesAndOrganizations();
    const {
        anchorAvatarEl, handleAvatarOpen, handleAvatarClose,
    } = useTopBar();

    const {toggleVisibility, isOpen} = ChangeLanguageDialog();

    return currentUser ? (
        <>
            <CommonAvatar name={currentUser.firstName || currentUser.lastName ? getName(currentUser.firstName, currentUser.lastName) : currentUser.email}
                          onClick={handleAvatarOpen}
                          size={'d28'} style={{margin: !revDir ? '0 0 0 8px' : '0 8px 0 0', cursor: 'pointer'}}/>
            <Menu
                keepMounted
                anchorEl={anchorAvatarEl}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                elevation={1}
                sx={{top: '19px'}}
                MenuListProps={{sx: {py: '0'}}}
                open={Boolean(anchorAvatarEl)}
                onClose={handleAvatarClose}
            >
                <AvatarMenuTitle name={getName(currentUser.firstName, currentUser.lastName)} email={currentUser.email}>
                    <CommonAvatar name={currentUser.firstName || currentUser.lastName ? getName(currentUser.firstName, currentUser.lastName) : currentUser.email}
                                  size={'d28'} style={{cursor: 'default'}}/>
                </AvatarMenuTitle>
                <Divider sx={{margin: '0 !important'}}/>

                <MenuItemHoverBlue onClick={() => {
                    toggleVisibility(true);
                    handleAvatarClose();
                }}>
                    <Typography color={colors.text.dark} variant={'*bodyText2'}>{t('Language')}</Typography>
                </MenuItemHoverBlue>

                {/*<MenuItem onClick={() => {history.push(PATH_LOCAL_USER_PROFILE)}}>*/}
                {/*    {t('Edit profile')}*/}
                {/*</MenuItem>*/}
                <Divider sx={{margin: '0 !important'}}/>
                <MenuItemHoverBlue onClick={() => {logout()}}>
                    <Typography color={colors.text.dark} variant={'*bodyText2'}>{t('Logout')}</Typography>
                </MenuItemHoverBlue>
            </Menu>

            <LanguageChangeDialog isOpen={isOpen} handleClose={toggleVisibility}/>
        </>
    ) : null
}