import React, {FC, useEffect, useState} from "react";
import {useMainTranslation} from "../../../../../newShared/hooks/useMainTranslationHooks/useMainTranslation";
import {useSignIn} from "../../hooks/useSignIn";
import {useRoutes} from "../../hooks/useRoutes";
import {useThreeDotsMenu} from "../../../../../newShared/hooks/useThreeDotsMenu";
import {Box, Menu, MenuItem, TextField, Typography} from "@mui/material";
import {DropdownButton, LoadingButton} from "../../../../../newShared/components/Basic/CommonButtons";
import {Language} from "@mui/icons-material";
import {SUPPORTED_LANGUAGES} from "../../../../../newShared/constants";
import colors from "../../../../../newShared/theme/colors";

export const BlockSignInDocsMfa: FC = () => {
    const {t, currentLanguage: {name, code}, handleChooseLanguage} = useMainTranslation('', {keyPrefix: ''});
    // const {isGoogleAuthConfigComplete} = UseManageWorkspacesAndOrganizations();

    const {
        docsLoginByEmail, docsConfirmMfa,
        isLoading: {isCheckingMfa, isResendingMfa},
        signInEmail,
    } = useSignIn();
    const {goToLogin, goToContactUs} = useRoutes();

    const {anchorThreeDotsEl, handleThreeDotsOpen, handleThreeDotsClose} = useThreeDotsMenu();
    const [mfaCode, setMfaCode] = useState<string>('');


    useEffect(() => {
        setMfaCode('')
    }, [isResendingMfa])

    return (
        <>
            <Typography variant={"h1"} textAlign={"center"}>{t('Login with one-time password')}</Typography>
            <Typography variant={"body1"} textAlign={"center"} mt={'8px'}>{t('We\'ve sent you email with password. Please enter password below.')}</Typography>

            <DropdownButton value={code} sx={{m: '16px auto 0', alignSelf: 'center'}} variant={"text"}
                            icon={<Language/>} open={!!anchorThreeDotsEl} onClick={handleThreeDotsOpen}>
                <Typography variant={"body1"}>{name}</Typography>
            </DropdownButton>
            <Menu open={!!anchorThreeDotsEl} anchorEl={anchorThreeDotsEl} onClose={handleThreeDotsClose}>
                {SUPPORTED_LANGUAGES.map((e) => (
                    <MenuItem value={e.code} key={e.code}
                              sx={{width: anchorThreeDotsEl?.offsetWidth}}
                              onClick={() => {
                                  handleChooseLanguage(e.code);
                                  handleThreeDotsClose();
                              }}>
                        {e.name}
                    </MenuItem>
                ))}
            </Menu>

            <TextField label={t('One-time password')} value={mfaCode} onChange={e => setMfaCode(e.target.value)}
                       sx={{mt: '16px'}} type={"password"}/>

            {/*{isGoogleAuthConfigComplete && (*/}
            {/*    <Link onClick={goToMfaRestore}*/}
            {/*          sx={{*/}
            {/*              alignSelf: 'flex-end', cursor: 'pointer',*/}
            {/*              fontWeight: 500, fontSize: '14px', lineHeight: '24px'*/}
            {/*          }}>*/}
            {/*        {t('Lost authenticator app?')}*/}
            {/*    </Link>*/}
            {/*)}*/}

            <Box display={"flex"} alignItems={"center"} gap={'16px'} mt={'16px'} width={'100%'}>
                <LoadingButton loading={isCheckingMfa} onClick={() => docsConfirmMfa(signInEmail || '', mfaCode)}
                               disabled={mfaCode.trim() === ''}>
                    {t('Continue')}
                </LoadingButton>

                {/*{SHOW_SKIP_BUTTON_IN_MFA && (*/}
                {/*    <Button variant={"outlined"}*/}
                {/*            onClick={() => docsConfirmMfa(signInEmail || '', '')}>*/}
                {/*        {t('SKIP')}*/}
                {/*    </Button>*/}
                {/*)}*/}
            </Box>

            <Typography sx={{mt: '16px', fontWeight: 500, fontSize: '18px', lineHeight: '24px'}}>
                {`${t('Back to')} `}<span onClick={goToLogin} style={{color: colors.primary.blue, cursor: 'pointer', fontWeight: 600}}>{t('Log in')}</span>
            </Typography>

            <Typography sx={{mt: '16px', fontWeight: 500, fontSize: '18px', lineHeight: '24px'}}>
                <span onClick={() => docsLoginByEmail(signInEmail || '')} style={{color: colors.primary.blue, cursor: 'pointer', fontWeight: 600}}>{t('Resend code')}</span>{` ${t('via email')}`}
            </Typography>

            <Typography sx={{mt: '16px', fontWeight: 500, fontSize: '18px', lineHeight: '24px'}}>
                {`${t('If you have a problem')} `}<span onClick={goToContactUs} style={{color: colors.primary.blue, cursor: 'pointer', fontWeight: 600}}>{t('Contact us')}</span>
            </Typography>
        </>
    )
}