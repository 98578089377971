import {ColumnType} from "./types";

export {};

export const DOCUMENTS_ROOT = '/documents';
export const MY_DOCUMENTS_PATH = `${DOCUMENTS_ROOT}/myDocuments`;
export const MY_DOCUMENTS_EXACT_PATH = `${DOCUMENTS_ROOT}/myDocuments/:id`;
export const SHARED_DOCUMENTS_PATH = `${DOCUMENTS_ROOT}/sharedDocuments`;
export const SHARED_DOCUMENTS_EXACT_PATH = `${DOCUMENTS_ROOT}/sharedDocuments/:id`;

export const availableColumns: ColumnType[] = [
    {
        key: 'documentName',
        normalizedName: 'Name'
    },
    {
        key: 'sender',
        normalizedName: 'Sender'
    },
    {
        key: 'workspaceName',
        normalizedName: 'Workspace name'
    },
    {
        key: 'lastModified',
        normalizedName: 'Last modified'
    },
    {
        key: 'recipients',
        normalizedName: 'Recipients'
    },
    // {
    //     key: 'expiredOn',
    //     normalizedName: 'Expired on'
    // },
    {
        key: 'status',
        normalizedName: 'Status'
    },
    {
        key: 'currentStepEta',
        normalizedName: 'Current step ETA'
    },
    {
        key: 'actions',
        normalizedName: 'Actions'
    },
];

export const availableColumnsSharedDocs: ColumnType[] = [
    {
        key: 'documentName',
        normalizedName: 'Name'
    },
    {
        key: 'sender',
        normalizedName: 'Sender'
    },
    {
        key: 'workspaceName',
        normalizedName: 'Workspace name'
    },
    {
        key: 'lastModified',
        normalizedName: 'Last modified'
    },
    {
        key: 'recipients',
        normalizedName: 'Recipients'
    },
    // {
    //     key: 'expiredOn',
    //     normalizedName: 'Expired on'
    // },
    {
        key: 'status',
        normalizedName: 'Status'
    },
    // {
    //     key: 'actions',
    //     normalizedName: 'Actions'
    // },
];

export const DEFAULT_PAGING = 20;

export const DOCUMENT_NAME_COLUMN_KEY = 'documentName';
export const WORKSPACE_NAME_COLUMN_KEY = 'workspaceName';
export const SENDER_COLUMN_KEY = 'sender';
export const LAST_MODIFIED_COLUMN_KEY = 'lastModified';
export const RECIPIENTS_COLUMN_KEY = 'recipients';
export const EXPIRED_ON_COLUMN_KEY = 'expiredOn';
export const STATUS_COLUMN_KEY = 'status';
export const ACTIONS_COLUMN_KEY = 'actions';
export const CURRENT_STEP_ETA_COLUMN_KEY = 'currentStepEta';

