import {Box, Table, TableBody, TableContainer} from "@mui/material";
import styled from "styled-components";
import colors from "../../../../../newShared/theme/colors";

export const PageContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
`;

export const DocsTableContainer = styled(TableContainer)`
  margin: 16px 0;
  width: 100%;
  box-shadow: 0 1px 3px rgba(16, 24, 40, 0.1), 0 1px 2px rgba(16, 24, 40, 0.06);
  border-radius: 8px !important;
  border: 1px solid #EAECF0;
  height: 100%;
  flex-grow: 1;
  overflow: auto;
`;

export const InboxTable = styled(Table)``;
InboxTable.defaultProps = {
    stickyHeader: true,
}

export const InboxTableBody = styled(TableBody)`
  & .MuiTableRow-root:last-child td {
    border-bottom: 1px solid ${colors.primary.disable};
`;
