import {createAsyncThunk} from "@reduxjs/toolkit";
import {
    initialValidateGoogleAuthorization,
    loginApi,
    loginDocsApi,
    loginDocsCodeApi,
    logoutApi,
    resendEmailVerificationCodeApi,
    sendEmailSupportApi,
    sendEmailToResetPass,
    sendPassToResetPass,
    signUpApi,
    verifyEmailApi
} from "../api";
import {LoginDocsCodeMutation, LoginMutationVariables} from "../../../../newShared/GQLTypes";
import {addInfoSnack, addSuccessfulSnack} from "../../../barsEnvironment/snack/store/slice";
import {TAuthObj} from "../types";

export const loginAction = createAsyncThunk(
    'auth/signIn',
    async (data: {data: LoginMutationVariables["data"], finallyFunc: (isAppConfigured: boolean, isEmailVerified: boolean) => void}, {rejectWithValue}) => {

        try {
            const resp = await loginApi({...data.data});
            data.finallyFunc && data.finallyFunc(resp.isGoogleAuthConfigComplete, resp.isEmailVerified); //going to 2fa

            return {
                email: data.data.username
            }
        } catch (err: any) {
            if (
                err && typeof err === "object" &&
                'message' in err && err.message && typeof err.message === "string"
            ) rejectWithValue(err.message)
        }


    }
);

export const logoutAction = createAsyncThunk(
    'auth/logout',
    async (_, {dispatch}) => {
        logoutApi()
    }
)


export const SendEmailSupportAction = createAsyncThunk(
    'auth/sendEmail',
    async (data: {email: string, name: string, message: string}, {dispatch}) => {
        await sendEmailSupportApi(data);
        return 'OK';
    }
)


export const sendEmailToResetPassAction = createAsyncThunk(
    'userProfile/sendEmailToResetPass',
    async (data: {email: string}, {dispatch, getState}) => {

        // dispatch(addSuccessfulSnack('We have sent a link to restore your password to your email.'))
        const resp = await sendEmailToResetPass(data);
        return resp
    },
);

export const sendPassToResetPassAction = createAsyncThunk(
    'userProfile/sendHashToResetPass',
    async (data: {password: string, hash: string, email: string, onSuccess: () => void}, {dispatch, getState}) => {
        const resp = await sendPassToResetPass({password: data.password, email: data.email, hash: data.hash});
        resp && data.onSuccess();
        return resp
    },
);

export const verifyEmailAction = createAsyncThunk(
    'auth/verifyEmail',
    async (data: {email: string, verificationCode: string, onSuccess: (skip: boolean) => void, signal?: AbortSignal}, {dispatch, getState}) => {
        const resp = await verifyEmailApi({verificationCode: data.verificationCode, email: data.email}, data.signal);
        dispatch(addSuccessfulSnack('Email verified successfully'))
        data.onSuccess(!resp.secret);
        return {
            data: {email: data.email},
            resp
        }
    },
);

export const resendEmailVerificationCodeAction = createAsyncThunk(
    'auth/resendEmailVerificationCode',
    async (data: {email: string, signal?: AbortSignal}, {dispatch, getState}) => {
        const resp = await resendEmailVerificationCodeApi({email: data.email}, data.signal);
        dispatch(addInfoSnack(resp.message))
        return {
            data: {email: data.email},
            resp
        }
    },
);

export const initialValidateGoogleAuthorizationAction = createAsyncThunk(
    'auth/initialValidateGoogleAuthorization',
    async (data: {secret: string, code: string, signal?: AbortSignal}, {dispatch, getState}) => {
        const resp = await initialValidateGoogleAuthorization(data.secret, data.code);
        dispatch(addInfoSnack('Application connected'))
        return {
            // data: {email: data.email},
            resp
        }
    },
);

export const signUpAction = createAsyncThunk(
    'signUp/signUpAction',
    async ({firstName, lastName, username, password, onSuccess, organizationName, license, invitationCode}: TAuthObj & {onSuccess: () => void}, { rejectWithValue }) => (
        signUpApi({firstName, lastName, username, password, organizationName, license, invitationCode}).then((data) => {
            onSuccess();
            localStorage.setItem('organizationName', organizationName);
            //its set here to use it in workspaces page - when user will create organization it will be places in org name field as init
            return data;
        }).catch((err) => {
            // console.log(err);
            return rejectWithValue(err.message);
        })
    ),
);



export const docsLoginAction = createAsyncThunk(
    'auth/docsLogin',
    async (data: {email: string, onSuccess: (isAppConfigured: boolean, isEmailVerified: boolean) => void}, {dispatch, getState}) => {
        const resp = await loginDocsApi({email: data.email});
        resp && data.onSuccess(resp.isGoogleAuthConfigComplete, resp.isEmailVerified);
        return resp
    },
);
export const docsLoginMfaAction = createAsyncThunk(
    'auth/docsLoginMfa',
    async (data: {email: string, code: string, onSuccess: (data: LoginDocsCodeMutation["loginDocsCode"]) => void}, {dispatch, getState}) => {
        const resp = await loginDocsCodeApi(data);
        resp && data.onSuccess(resp);
        return resp
    },
);