import {APPROVAL_STATUS, SIGNING_STATUS, TDocumentStatus, TShortDocument} from "../../types";
import {FC} from "react";
import {useMainTranslation} from "../../../../../newShared/hooks/useMainTranslationHooks/useMainTranslation";
import * as S from './styled';
import {IconButton, TableCell, TableRow, Typography} from "@mui/material";
import {getStatusColor} from "../../helpers";
import {COMPLETED_STATUS} from "../../../../../newShared/components/editorUnion/types";
import {DownloadFile} from "../../store/actions";
import {useDispatch} from "react-redux";
import colors from "../../../../../newShared/theme/colors";
import DownloadIcon from '@mui/icons-material/Download';
import {diffBetweenDateAndNow, parseDateAuto} from "../../../../../newShared/utils/dateTools";
import {getExpiredTitleColor} from "../../../../../newShared/components/editorUnion/helpers";


interface DocumentTableRowProps {
    file: TShortDocument,
    openFile: (e: React.MouseEvent<HTMLTableRowElement, MouseEvent>, file: TShortDocument) => void,
    openRecipientsDialog: (recipients: TShortDocument["recipients"]) => void,
}
export const MyDocumentTableRow: FC<DocumentTableRowProps> = ({file, openRecipientsDialog, openFile}) => {
    const dispatch = useDispatch();
    const {revDir, currentLanguage:{momentLocale}} = useMainTranslation('', {keyPrefix: 'pathDocuments.ExplorerPage'});

    const statusColor = getStatusColor(file.status as TDocumentStatus);

    const handleDownload = () => {
        if(file.type === 'DOCUMENT' && file.generatedFileId ){
            dispatch(DownloadFile({fileId: file.generatedFileId, fileName: `${file.name}.pdf`}));
        }
    }

    const isDownloadDisabled = file.status !== COMPLETED_STATUS || !file.generatedFileId;

    const diff = diffBetweenDateAndNow(file.currentStepEndDate || '', momentLocale);

    const expiredText =
        (file.status === APPROVAL_STATUS || file.status === SIGNING_STATUS)
            ? getExpiredTitleColor(diff).title
            : '-';

    return(
        <TableRow hover onClick={(e) => openFile(e, file)}>
            <S.DocNameTableCell>
                <Typography variant={'*bodyText2'} noWrap>{file.name}</Typography>
            </S.DocNameTableCell>
            <TableCell>
                <Typography variant={'*bodyText2'} noWrap>{file.sender ?? '-'}</Typography>
            </TableCell>
            <TableCell>
                <Typography variant={'*bodyText2'} noWrap>{file.workspaceName ?? '-'}</Typography>
            </TableCell>

            <S.DocLastModifiedTableCell>
                <Typography variant={'*bodyText2'}>{parseDateAuto(file.lastModified ?? file.createdDate)}</Typography>
            </S.DocLastModifiedTableCell>

            <S.DocRecipientsTableCell align={"center"}>
                {file.recipients?.length && (
                    <S.DocumentAvatarGroup onClick={() => openRecipientsDialog(file.recipients)}
                                           revDir={revDir} max={4} total={file.recipients.length} >
                        {file.recipients.map((e, index) => (
                            <S.DocumentAvatar key={e.actor?.email} index={index} name={`${e.actor?.firstName || e.actor?.email} ${e.actor?.lastName || e.actor?.email}`} alt={e.actor?.email ?? ''} />
                        ))}
                    </S.DocumentAvatarGroup>
                )}
            </S.DocRecipientsTableCell>

            <S.DocStatusTableCell>
                <S.StatusTableCellContainer>
                    <S.StatusRoundBox color={statusColor} revDir={revDir}/>
                    <S.StatusTypography color={statusColor}>{file?.status?.toUpperCase()}</S.StatusTypography>
                </S.StatusTableCellContainer>
            </S.DocStatusTableCell>

            <S.DocExpiredTableCell color={getExpiredTitleColor(diff).color}>
                <Typography variant={'*bodyText2'}>{expiredText}</Typography>
            </S.DocExpiredTableCell>

            <S.DocStatusTableCell>
                <S.StatusTableCellContainer>
                    <IconButton size={'small'} onClick={handleDownload} disabled={isDownloadDisabled}>
                        <DownloadIcon sx={{color: isDownloadDisabled ? colors.primary.disable : undefined}}/>
                    </IconButton>
                </S.StatusTableCellContainer>
            </S.DocStatusTableCell>
        </TableRow>
    )
}
