//paths


import {
    PATH_LOCAL_HELP,
    PATH_LOCAL_INBOX,
    PATH_LOCAL_NEW_WORKSPACES,
    PATH_LOCAL_USER_PROFILE,
} from "../../../newShared/constants";
// import {PATH_LOCAL_SHARE_REPORTS, PATH_LOCAL_SPACE} from "../../../domain/shell/constants";
import {DOCUMENTS_ROOT} from "../../components/documents/constants";

export const SELECT_WORKSPACES_PATH = '/selectWorkspace';
export const CREATE_ORGANIZATION = '/createOrganization';
export const MANAGE_ORGANIZATION = '/manageOrganization';
export const MANAGE_WORKSPACE = '/manageWorkspace';
export const INVITES = '/invites';

export const ACCEPT_INVITATION = '/invitation';
export const ACCEPT_INVITATION_PROTECTED = '/protected_invitation';
export const ACCEPT_INVITATION_UNPROTECTED = '/unprotected_invitation';


//roles
export const WORKSPACE_VIEWER = 'viewer';
export const WORKSPACE_EDITOR = 'editor';
export const WORKSPACE_ADMIN = 'admin';
export const WORKSPACE_OWNER = 'owner';

export const ORGANIZATION_MEMBER = 'member';
export const ORGANIZATION_ADMIN = 'admin';
export const ORGANIZATION_OWNER = 'owner';

export const PATHS_AVAILABLE_WITH_NO_WORKSPACE = [
    PATH_LOCAL_HELP,
    ACCEPT_INVITATION,
    ACCEPT_INVITATION_PROTECTED,
    ACCEPT_INVITATION_UNPROTECTED,
    // PATH_LOCAL_SHARE_REPORTS,
    PATH_LOCAL_USER_PROFILE,
    PATH_LOCAL_NEW_WORKSPACES + SELECT_WORKSPACES_PATH,
    PATH_LOCAL_NEW_WORKSPACES + CREATE_ORGANIZATION,
    PATH_LOCAL_NEW_WORKSPACES + MANAGE_ORGANIZATION,
    PATH_LOCAL_NEW_WORKSPACES + MANAGE_WORKSPACE,
    PATH_LOCAL_NEW_WORKSPACES + INVITES,
    PATH_LOCAL_INBOX,
    DOCUMENTS_ROOT
];

export const isPathAvailableWithNoWorkspace = (pathname: string): boolean => {
    return  PATHS_AVAILABLE_WITH_NO_WORKSPACE.some(e => pathname.startsWith(e));
}

export const PATHS_UNPROTECTED_BUT_AVAILABLE_FOR_LOGGED_IN = [
    //for example - invitation - this path have to be opened for signed in user and for not signed ether
    ACCEPT_INVITATION,
    // PATH_LOCAL_SHARE_REPORTS,
]

export const isPathAvailableForEveryone = (pathname: string): boolean => {
    return  PATHS_UNPROTECTED_BUT_AVAILABLE_FOR_LOGGED_IN.some(e => pathname.startsWith(e));
}


export const INVITE_USER_ROLES = [ORGANIZATION_MEMBER, ORGANIZATION_ADMIN];
export const INVITE_USER_WORKSPACE_ROLES = [WORKSPACE_VIEWER, WORKSPACE_EDITOR, WORKSPACE_ADMIN]

