import React, {FC} from "react";
import {Redirect, Route, Switch} from "react-router";
import {PATH_LOCAL_2FA, PATH_LOCAL_LOGIN, PATH_LOCAL_LOGIN_CONTACT_US} from "../../../newShared/constants";
import {BlockSignInDocs} from "./pages/blockSignInStaff";
import {BlockSignInDocsMfa} from "./pages/blockSignInStaffMfa";
import {BlockContactUs} from "./pages/blockContactUs";


export const Routes: FC = () => {
    
    return (
        <Switch>
            <Route exact path={PATH_LOCAL_LOGIN} component={BlockSignInDocs}/>
            <Route exact path={PATH_LOCAL_2FA} component={BlockSignInDocsMfa}/>

            {/*<Route exact path={PATH_LOCAL_VERIFY_EMAIL} component={BlockEmailVerification}/>*/}

            {/*<Route exact path={PATH_LOCAL_PASSWORD_RESET} component={BlockSignInRestorePassword}/>*/}
            {/*<Route exact path={PATH_LOCAL_MFA_RESET} component={BlockSignInRestoreMfa}/>*/}

            {/*<Route exact path={PATH_LOCAL_SIGNUP} component={BlockSignUp}/>*/}
            {/*<Route exact path={PATH_LOCAL_INITIAL_MFA_CONFIGURE} component={BlockInitialMfaApp}/>*/}

            <Route exact path={PATH_LOCAL_LOGIN_CONTACT_US} component={BlockContactUs}/>

            <Redirect to={PATH_LOCAL_LOGIN}/>
        </Switch>
    )
}