import {PATH_SERVER} from "../../../constants";
import {print} from "graphql";
import {gql} from "@apollo/react-hooks";
import {AxiosResponse} from "axios";
import axiosSync from "../../../utils/axios/axios"
import {buildCommonHeader} from "../../../utils/builders";
import {DownloadAuditorEvidenceFileDocsQuery} from "../../../GQLTypes";

const downloadAuditorEvidenceFile = gql`
    query downloadAuditorEvidenceFileDocs($fileId: String!, $filename: String!){
        downloadAuditorEvidenceFileDocs (fileId: $fileId, filename: $filename) {
            filename
            filetype
            file
        }
    }
`

export const getFile = async (fileId: string, filename: string): Promise<DownloadAuditorEvidenceFileDocsQuery["downloadAuditorEvidenceFileDocs"]> => (
    await axiosSync.then(
        (axios) => axios.post(
            PATH_SERVER,
            {
                query: print(downloadAuditorEvidenceFile),
                variables: {
                    fileId,
                    filename,
                }
            },
            {
                headers: buildCommonHeader()
            }
        )
    ).then((result: AxiosResponse<any>) => {
        return result.data.data.downloadAuditorEvidenceFileDocs;
    })
)