import React, {FC} from "react";
import {useMainTranslation} from "../../../../../newShared/hooks/useMainTranslationHooks/useMainTranslation";
import {useSignIn} from "../../hooks/useSignIn";
import {Menu, MenuItem, TextField, Typography} from "@mui/material";
import {SUPPORTED_LANGUAGES} from "../../../../../newShared/constants";
import {useThreeDotsMenu} from "../../../../../newShared/hooks/useThreeDotsMenu";
import {DropdownButton, LoadingButton} from "../../../../../newShared/components/Basic/CommonButtons";
import {Language} from "@mui/icons-material";
import {useForm} from "../../../../../newShared/hooks/useForm";
import {useRoutes} from "../../hooks/useRoutes";
import colors from "../../../../../newShared/theme/colors";
import {useDispatch} from "react-redux";
import {SendEmailSupportAction} from "../../store/actions";

export const BlockContactUs: FC = () => {
    const {t, currentLanguage: {name, code}, handleChooseLanguage} = useMainTranslation('', {keyPrefix: ''});

    const dispatch = useDispatch();
    const sendEmail = () => {
        dispatch(SendEmailSupportAction(form));
    }

    const {
        isLoading: {isSendingEmail}
    } = useSignIn();
    const {goToLogin} = useRoutes();

    const {form, handleChange} = useForm<{email: string, name: string, message: string}>({email: '', name: '', message: ''});

    const {anchorThreeDotsEl, handleThreeDotsOpen, handleThreeDotsClose} = useThreeDotsMenu();

    return (
        <>
            <Typography variant={"h1"} textAlign={"center"}>{t('Contact us')}</Typography>

            <DropdownButton value={code} sx={{m: '32px auto 0', alignSelf: 'center'}} variant={"text"}
                            icon={<Language/>} open={!!anchorThreeDotsEl} onClick={handleThreeDotsOpen}>
                <Typography variant={"body1"}>{name}</Typography>
            </DropdownButton>
            <Menu open={!!anchorThreeDotsEl} anchorEl={anchorThreeDotsEl} onClose={handleThreeDotsClose}>
                {SUPPORTED_LANGUAGES.map((e) => (
                    <MenuItem value={e.code} key={e.code}
                              sx={{width: anchorThreeDotsEl?.offsetWidth}}
                              onClick={() => {
                                  handleChooseLanguage(e.code);
                                  handleThreeDotsClose();
                              }}>
                        {e.name}
                    </MenuItem>
                ))}
            </Menu>

            <TextField name={'email'} label={t('Email')} value={form.email} onChange={handleChange}
                       required sx={{mt: '16px'}} type={"email"}/>

            <TextField name={'name'} label={t('Full Name')} value={form.name} onChange={handleChange}
                       required sx={{mt: '16px'}}/>

            <TextField name={'message'} label={t('Describe your message')} value={form.message} onChange={handleChange}
                       required sx={{mt: '16px'}} multiline minRows={3} maxRows={3}/>

            <LoadingButton loading={isSendingEmail} onClick={sendEmail}
                           disabled={form.email.trim() === '' || form.name.trim() === '' || form.message.trim() === ''}
                           sx={{mt: '16px', alignSelf: 'flex-start'}}>
                {t('Send')}
            </LoadingButton>

            <Typography sx={{mt: '16px', fontWeight: 500, fontSize: '18px', lineHeight: '24px'}}>
                {`${t('Return to')} `}<span onClick={goToLogin} style={{color: colors.primary.blue, cursor: 'pointer', fontWeight: 600}}>{t('Log In')}</span>
            </Typography>
        </>
    )
}
