import {Box, Button, Dialog, DialogActions, Tab, Typography} from "@mui/material";
import {TSignDialogProps} from "./types";
import {useSignDialog} from "./hooks/useSignDialog";
import * as S from './components/styled';
import TabContext from "@mui/lab/TabContext/TabContext";
import {TabList, TabPanel} from "@mui/lab";
import colors from "../../../../../../theme/colors";
import React from "react";
import CommonSwitch from "../../../../../Basic/CommonSwitch";
import {FileDrop} from "react-file-drop";
import {ACCEPT_IMAGE_PROP_WITHOUT_SVG} from "../../../../../../constants";


const SignatureCanvas = require('react-signature-canvas-react17-compatible').default;

export const SignDialog = (props: TSignDialogProps) => {
    const {
        state,
        handleChange,
        setCanvas,
        handleClearCanvas,
        handleToggleSwitch,
        handleSubmit,
        isOk,
        handleChangeTypeInput,
        handleClearInputOrFile,
        filePicker,
        fileHandler,
        ref,
        handleRemoveFile
    } = useSignDialog(props);
    return(
        <Dialog open={props.isOpen} onClose={props.handleClose} maxWidth={'xs'}>
            <S.DialogTitleContainer>
                {/*<SignatureIcon />*/}
                <S.DialogTitle>{('Signature')}</S.DialogTitle>
            </S.DialogTitleContainer>

            <TabContext value={state.tab}>
                <Box sx={{ borderBottom: 2, borderColor: colors.stroke.grey}}>
                    <TabList onChange={handleChange} aria-label="lab API tabs example" centered>
                        <Tab label={<S.DialogTabTitle>{('Draw')}</S.DialogTabTitle>} value="draw" />
                        <Tab label={<S.DialogTabTitle>{('Type')}</S.DialogTabTitle>} value="type" />
                        <Tab label={<S.DialogTabTitle>{('Upload')}</S.DialogTabTitle>} value="upload" />
                    </TabList>
                </Box>

                <TabPanel value="draw">
                    <S.SignCanvasContainer>
                        <SignatureCanvas
                            ref={(ref: any) => setCanvas(ref)}
                            penColor='black'
                            backgroundColor={colors.backgrounds.grey_light}
                            canvasProps={{width: '400px', height: '140px', className: 'sigCanvas'}}
                        />

                        <S.SignCanvasLineContainer>
                            <S.SignDivider />
                            <S.ClearButton onClick={handleClearCanvas}/>
                        </S.SignCanvasLineContainer>
                    </S.SignCanvasContainer>

                    <S.SignSwitchContainer>
                        <CommonSwitch label={<S.SwitchTitle>{('Insert signature everywhere')}</S.SwitchTitle>}
                                      value={state.insertEverywhere}
                                      onChange={(e, checked) => handleToggleSwitch(checked)}
                        />
                        <S.SwitchDescTitle>{'The signature will be entered in every signature field within the document.'}</S.SwitchDescTitle>
                    </S.SignSwitchContainer>

                </TabPanel>

                <TabPanel value="type">
                    <S.SignInputContainer>
                        <S.SignInput type="text" value={state.sign} onChange={handleChangeTypeInput}/>
                        <S.SignCanvasLineContainer>
                            <S.SignDivider />
                            <S.ClearButton onClick={handleClearInputOrFile}/>
                        </S.SignCanvasLineContainer>
                    </S.SignInputContainer>

                    <S.SignSwitchContainer>
                        <CommonSwitch label={<S.SwitchTitle>{('Insert signature everywhere')}</S.SwitchTitle>}
                                      value={state.insertEverywhere}
                                      onChange={(e, checked) => handleToggleSwitch(checked)}
                        />
                        <S.SwitchDescTitle>{'The signature will be entered in every signature field within the document.'}</S.SwitchDescTitle>
                    </S.SignSwitchContainer>
                </TabPanel>

                <TabPanel value="upload">
                    <FileDrop onTargetClick={filePicker} onDrop={(f: FileList | null) => fileHandler(f)}>
                        <S.DropZoneWrapper>
                            <S.DropZoneInnerWrapper>
                                {state.file === null ?
                                    <Typography align={'center'}>
                                        <b style={{color: colors.text.blue, fontWeight: 400}}>{('Drag & drop a file to this area')} </b>
                                        <br/>
                                        <br/>
                                        {('or')}
                                        <br/>
                                        <br/>
                                        <Button variant={'contained'} size={'small'}>{('Select a file')}</Button>
                                    </Typography>
                                    :
                                    <S.ImageContainer>
                                        <img alt={''} src={state.file} style={{maxWidth: '200px', maxHeight: '100px'}}
                                             // width={'200px'} height={'100%'}
                                        />
                                        <S.ClearImageButton onClick={handleRemoveFile}/>
                                    </S.ImageContainer>
                                }
                            </S.DropZoneInnerWrapper>

                            <input
                                accept={ACCEPT_IMAGE_PROP_WITHOUT_SVG}
                                value=""
                                style={{ visibility: "hidden", opacity: 0 }}
                                ref={ref}
                                type="file"
                                onChange={(e) => fileHandler(e.target.files)}
                            />
                        </S.DropZoneWrapper>
                    </FileDrop>
                </TabPanel>

            </TabContext>

            <S.ActionDescTitle>{'By clicking Accept & Sign I understand and agree that this is a legal representation of my signature.'}</S.ActionDescTitle>
            <DialogActions>
                <Button onClick={props.handleClose} variant={'text'}>{'Cancel'}</Button>
                <Button onClick={handleSubmit} disabled={!isOk} variant={'contained'}>{'Accept & Sign'}</Button>
            </DialogActions>
        </Dialog>
    )
}