import {FC} from "react";
import {Redirect, Route, Switch} from "react-router";
import {
    MY_DOCUMENTS_EXACT_PATH,
    MY_DOCUMENTS_PATH,
    SHARED_DOCUMENTS_EXACT_PATH,
    SHARED_DOCUMENTS_PATH
} from "./constants";
import {MyDocs} from "./pages/myDocs";
import {SharedDocs} from "./pages/sharedDocs";

export const Routes: FC = () => {
    return (
        <Switch>
            <Route exact path={[MY_DOCUMENTS_PATH, MY_DOCUMENTS_EXACT_PATH]} component={MyDocs}/>
            <Route exact path={[SHARED_DOCUMENTS_PATH, SHARED_DOCUMENTS_EXACT_PATH]} component={SharedDocs}/>
            <Redirect to={MY_DOCUMENTS_PATH}/>
        </Switch>
    )
}
