import {filterDateType, filterType, keyConfig} from "../../types";
import React, {useCallback, useEffect, useMemo, useState} from "react";
import debounce from "lodash.debounce";
import {GENERIC_FILTER_OPTIONS_DEFAULT_PAGING, PARTICULAR_FILTER_DEBOUNCE} from "../../constants";
import {useMainTranslation} from "../../../../hooks/useMainTranslationHooks/useMainTranslation";
import {TPageInfo} from "../../../../types";
import colors from "../../../../theme/colors";
import {useDispatch, useSelector} from "react-redux";
import {currentFilterSelector, handleSetOriginalFiltersByKey} from "../../store/slice";
import {getNormalizedForBackendDateDay, isPastDate, isSameDay} from "../../../../utils/dateTools";

export const useGenericFilter = <T>(config: keyConfig<T>, updateFilterAndFetch: (filterKey: string, filterValues: string[]) => void, handleDeselectVisibleFilter: (filter: filterType) => void) => {
    const currentFilter = useSelector(currentFilterSelector);

    const dispatch = useDispatch();
    const {currentLanguage} = useMainTranslation();
    const isLocalFilter = !config.fetchOptions; 
    //FILTER DIALOG OR MENU
    // const [isOpen, setIsOpen] = useState<boolean>(false);
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const id = open ? config.key : undefined;

    useEffect(() => {
        if(!open){
            controller.abort();
        }
        //eslint-disable-next-line
    }, [open]);

    // FETCH
    const controller = new AbortController();

    const sendRequest = useCallback((_value: string, _page: number, _count: number) => {
        //its used only by search - so replacing all values
        try {
            config.fetchOptions && config.fetchOptions({page: _page, count: _count, search: _value}, controller.signal).then(res => {
                setPageInfo(res.pageInfo);
                setOptions(res.values);
            });
        }catch (ex){
            console.log(`config.fetchOptions ex: ${JSON.stringify(ex)}`);
        }
        //eslint-disable-next-line
    }, [config.fetchOptions]);

    const debouncedSendRequest = useMemo(() => {
        return debounce(sendRequest, PARTICULAR_FILTER_DEBOUNCE);
    }, [sendRequest]);
    //FETCH
    //
    const handleClose = () => {
        // throw new Error('handleClose');
        // console.log(`handleClose`);
        setAnchorEl(null);
    }

    const handleOpen = (event: React.MouseEvent<HTMLElement>) => {
        // console.log(`handleOpen`)
        if(isLocalFilter){
            //if local filter and its reopens - resetting options to show it like new
            setOptions(config.options ?? []);
        }else{
            //if nonlocal filter and its reopens - fetching by empty string
            fetchOptions(0, GENERIC_FILTER_OPTIONS_DEFAULT_PAGING, '');
        }
        setAnchorEl(anchorEl ? null : event.currentTarget);
    }

    //SEARCH
    const [search, setSearch] = useState<string>('');

    const handleChangeSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
        const value = event.currentTarget.value as string;
        setSearch(value);
        if(isLocalFilter){
            filterBySearch(value);
        }else{
            debouncedSendRequest(value, 0, GENERIC_FILTER_OPTIONS_DEFAULT_PAGING);
        }
    }

    //OPTIONS
    const [options, setOptions] = useState<T[]>([]); //only for fetched options
    const [locallyFilteredOptions, setLocallyFilteredOptions] = useState<T[]>([]);
    const [selectedOptions, setSelectedOptions] = useState<T[]>((currentFilter.originalSelectedValues[config.key] as T[]) ?? []);
    const [pageInfo, setPageInfo] = useState<TPageInfo>({page: 0, count: 0, total: 0});
    const [isLoadingFetch, setIsLoadingFetch] = useState<boolean>(false);

    useEffect(() => {
        if(!Object.keys(currentFilter.filters).length){
            setSelectedOptions([]);
        } 
    }, [currentFilter.filters]);

    const fetchOptions = (page: number, count: number, value: string, callback?: (res: {values: T[], pageInfo: TPageInfo}) => void) => {
        setIsLoadingFetch(true);
        config.fetchOptions && config.fetchOptions({page, count, search: value}, controller.signal)
            .then((res) => {
                if(callback){
                    callback(res);
                }else{
                    setOptions(res.values);
                    setPageInfo(res.pageInfo);
                }
            });
        setIsLoadingFetch(false);
    }

    const filterBySearch = (searchValue: string) => {
        if(isLocalFilter && config.options){
            let filtered:T[];
            if(config.isOptionEqualToSearch !== undefined){
                filtered = config.options.filter(e => config.isOptionEqualToSearch!(e, searchValue));
            }else{
                filtered = config.options.filter(e => (e as string).trim().toLowerCase().startsWith(searchValue.trim().toLowerCase()));
            }
            // console.log(`local filter: ${JSON.stringify(filtered)}`);
            setLocallyFilteredOptions(filtered);
        }else{
            //fetching
            fetchOptions(0, GENERIC_FILTER_OPTIONS_DEFAULT_PAGING, searchValue);
        }
    }

    const handleLoadMore = () => {
        const callBack = (res: { values: T[], pageInfo: TPageInfo }) => {
            setOptions(prev => {return [...prev, ...res.values]});
            setPageInfo(res.pageInfo);
        }
        fetchOptions(pageInfo.page + 1, GENERIC_FILTER_OPTIONS_DEFAULT_PAGING, search, callBack);
    }

    //fillWithSelected
    const fillOptionsWithSelectedBool = (_options: T[], _selectedOptions: T[]): {value: T, selected: boolean, key: string}[] => {
        const res: {value: T, selected: boolean, key: string}[] = [];
        _options.forEach(e => {
            let selected;
            if(config.isOptionEqual !== undefined){
                selected = _selectedOptions.some(so => config.isOptionEqual!(e, so));
            }else{
                selected = _selectedOptions.some(so => so === e);
            }
            let key;
            if(config.getFilterValue !== undefined){
                key = config.getFilterValue(e);
            }else{
                key = `${e}_id`;
            }
            res.push({value: e, selected, key});
        })
        return res;
    }

    //---
    const handleSelectOption = (option: T) => {
        // console.log(`handleSelectOption: ${JSON.stringify(option)}`)
        const _selectedOptions = [...selectedOptions, option];
        setSelectedOptions(_selectedOptions);
        dispatch(handleSetOriginalFiltersByKey({key: config.key, values: _selectedOptions}))
        if(config.getFilterValue !== undefined){
            updateFilterAndFetch(
                config.key,
                _selectedOptions.map(e => config.getFilterValue!(e))
            );
        }else{
            updateFilterAndFetch(
                config.key,
                _selectedOptions as string[]
            );
        }
    }

    const handleDeselectOption = (option: T) => {
        let _selectedOptions;
        if(config.isOptionEqual !== undefined){
            _selectedOptions = selectedOptions.filter(e => !config.isOptionEqual!(e, option));
        }else{
            _selectedOptions = selectedOptions.filter(e => e !== option);
        }
        setSelectedOptions(_selectedOptions);
        dispatch(handleSetOriginalFiltersByKey({key: config.key, values: _selectedOptions}))
        if(config.getFilterValue !== undefined){
            updateFilterAndFetch(
                config.key,
                _selectedOptions.map(e => config.getFilterValue!(e))
            );
        }else{
            updateFilterAndFetch(
                config.key,
                _selectedOptions as string[]
            );
        }
    }

    const handleResetSelection = () => {
        setSelectedOptions([]);
        dispatch(handleSetOriginalFiltersByKey({key: config.key, values: []}));
        updateFilterAndFetch(config.key, []);
    }

    const getOptions = ():{selectedAndNotFoundInList: {value: T, selected: boolean, key: string}[], list: {value: T, selected: boolean, key: string}[]} => {
        const allSelected = fillOptionsWithSelectedBool(selectedOptions, selectedOptions);
        if(isLocalFilter && config.options){
            if(search.trim().length > 0){
                const list = fillOptionsWithSelectedBool(locallyFilteredOptions, selectedOptions); //filtered from config values
                return {
                    selectedAndNotFoundInList: [],
                    list
                }
            }else{
                const list = fillOptionsWithSelectedBool(config.options, selectedOptions); //full array from config
                return {
                    selectedAndNotFoundInList: [],
                    list
                }
            }
        }else{
            // console.log(`getOptions - nonlocal fetched array`)
            const list = fillOptionsWithSelectedBool(options, selectedOptions); //fetched options

            let selectedAndNotFoundInList;
            if(config.isOptionEqual !== undefined){
                selectedAndNotFoundInList = allSelected.filter(e => !list.some(l => config.isOptionEqual!(e.value, l.value)));
            }else{
                selectedAndNotFoundInList = allSelected.filter(e => !list.some(l => e.value === l.value));
            }
            return {
                selectedAndNotFoundInList,
                list
            }
        }
    }

    //date
    const storedDateValues = currentFilter.originalSelectedValues[config.key]?.map(e => e !== null ? new Date(e) : e);
    const [dateType, setDateType] = useState<filterDateType>(storedDateValues?.length > 0 ? 'period' : 'exact');

    const handleSelectDateType = (type: filterDateType) => {
        setSelectedDates([]);
        setDateType(type);
        updateFilterAndFetch(config.key, []);
        dispatch(handleSetOriginalFiltersByKey({key: config.key, values: []}))
    }

    const [selectedDates, setSelectedDates] = useState<(Date | null)[]>(storedDateValues ?? []);

    const handleSelectExactDate = (date: Date | null) => {
        setSelectedDates([date]);
        if(date){
            const normalizedBackendDate = getNormalizedForBackendDateDay(date?.toISOString());
            updateFilterAndFetch(config.key, [normalizedBackendDate]);
            dispatch(handleSetOriginalFiltersByKey({key: config.key, values: [normalizedBackendDate]}));
        }
    }

    const handleSelectRangeDateA = (dateA: Date | null) => {
        const newDates = [dateA, selectedDates[1] ?? null];
        setSelectedDates(newDates);
        if(isOkRange(newDates)){
            const values = newDates.filter(e => e !== null && e !== undefined).map(e => getNormalizedForBackendDateDay(e!.toISOString()));
            updateFilterAndFetch(config.key, values);
            dispatch(handleSetOriginalFiltersByKey({key: config.key, values}));
        }
    }

    const handleSelectRangeDateB = (dateB: Date | null) => {
        const newDates = [selectedDates[0] ?? null, dateB];
        setSelectedDates(newDates);
        if(isOkRange(newDates)){
            const values = newDates.filter(e => e !== null && e !== undefined).map(e => getNormalizedForBackendDateDay(e!.toISOString()));
            updateFilterAndFetch(config.key, values);
            dispatch(handleSetOriginalFiltersByKey({key: config.key, values}));
        }
    }

    const isOkRange = (_selectedDates: (Date | null)[]): boolean => {
        // console.log(`isOkRange: ${JSON.stringify(_selectedDates)}`);
        if(_selectedDates.filter(e => e !== null).length < 2) return false;
        // console.log(`isOkRange - 2 or more dates`);
        return isSameDay(_selectedDates[0]!.toISOString(), _selectedDates[1]!.toISOString(), currentLanguage.momentLocale) ||
            isPastDate(_selectedDates[1]!.toISOString(), currentLanguage.momentLocale, _selectedDates[0]!.toISOString()) ||
            isPastDate(_selectedDates[0]!.toISOString(), currentLanguage.momentLocale, _selectedDates[1]!.toISOString())
    }

    const handleResetDateFilter = () => {
        setSelectedDates([]);
        updateFilterAndFetch(config.key, []);
        dispatch(handleSetOriginalFiltersByKey({key: config.key, values: []}));
    }

    //other
    const isUsedFilter = selectedOptions.length > 0 || selectedDates.filter(e => e !== null).length > 0;

    const getChipBackgroundColor = () => {
        if(isUsedFilter || open){
            return colors.chip.pressed;
        }else{
            return  colors.chip.default
        }
    }

    const getChipTextColor = () => {
        if(isUsedFilter || open){
            return colors.text.white;
        }else{
            return  colors.text.grey_dark
        }
    }

    //chip click
    // const ref = React.createRef<HTMLButtonElement>();

    // const onChipClick = () => {
    //     // console.log(`onChipClick: ${config.key} | ${isOpen}`);
    //     if(!open && ref.current){
    //         ref.current.click();
    //         // console.log(`onChipClick: ${config.key} REF OK`);
    //     }
    // };

    const handleDeleteSelf = () => {
        updateFilterAndFetch(config.key, []);
        handleDeselectVisibleFilter({key: config.key, name: config.name});
        dispatch(handleSetOriginalFiltersByKey({key: config.key, values: []}));
    }
    return{
        filterGeneral: {
            isOpen: open,
            anchorEl,
            id,
            handleClose: () => {
                if(config.type === 'date' && dateType === 'period' && !isOkRange(selectedDates)){
                    setSelectedDates([]);
                    updateFilterAndFetch(config.key, []);
                }
                handleClose();
            },
            handleOpen,
            isUsedFilter,
            chipBackgroundColor: getChipBackgroundColor(),
            chipTextColor: getChipTextColor(),
            // ref,
            // onChipClick,
            handleDeleteSelf
        },
        search: {
            value: search,
            onChange: handleChangeSearch,
            isEmptySearch: search.trim().length === 0
        },
        options: {
            options: getOptions(),
            selectedOptions,
            handleSelectOption,
            handleDeselectOption,
            handleLoadMore,
            hasMore: isLocalFilter ? false : pageInfo.total > ((pageInfo.page + 1) * pageInfo.count),
            isLoading: isLoadingFetch,
            firstSelectedOption: selectedOptions[0],
        },
        bottomData: {
            total: config.options?.length ?? pageInfo.total,
            handleResetSelection
        },
        date: {
            dateType,
            handleSelectDateType,
            selectedDates,
            handleSelectExactDate,
            handleSelectRangeDateA,
            handleSelectRangeDateB,
            handleResetDateFilter,
            isResetButtonDisabled: !selectedDates.filter(e => e !== null).length,
            isSelectedAtLeastOne: selectedDates.filter(e => e !== null).length > 0,
            isSelectedAndFilledRange: selectedDates.filter(e => e !== null).length === 2,
        }
    }
}