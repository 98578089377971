import {CustomButton, RoundButton} from "../Buttons";
import {Flex} from "../Layouts";
import React, {useRef, useState} from "react";
import {Fade, Menu, MenuItem, Typography} from "@mui/material";

//images
import Any from '../../images/empty/Illustration.svg';

import {useHistory} from "react-router-dom";
import {useMainTranslation} from "../../hooks/useMainTranslationHooks/useMainTranslation";
import {PATH_LOCAL_HELP} from "../../constants";
import colors from "../../theme/colors";


type TPropsTypeOfEmptyArrayImage =
    'frameworks' | 'automations' |
    'collectors' | 'documents' |
    'reports' | 'analysis' |
    'builder' | 'organizations' |
    'quickStart' | 'availableInPaidOnly' |
    'risks' | 'hrManagement' | 'vulnerability'
    | 'physicalAssets' | 'vendors'

type TProps = {
    type: TPropsTypeOfEmptyArrayImage,
    isShowing: boolean,
    text: string,
    secondaryText?: string,
    onClick?: () => void,
    menu?: {title: string, onClick: () => void}[],
    withoutAdd?: boolean,
}

const getImage = (type: TPropsTypeOfEmptyArrayImage) => {
    switch (type){
        case 'frameworks': return Any;
        case "automations": return Any;
        case "collectors": return Any;
        case "documents": return Any;
        case "reports": return Any;
        case "risks": return Any;
        case "analysis": return Any;
        case "builder": return Any;
        case "quickStart": return Any;
        case "organizations": return Any;
        case "availableInPaidOnly": return Any;
        case "hrManagement": return Any;
        case "vulnerability": return Any;
        case "physicalAssets": return Any;
        case "vendors": return Any;
        default: throw new Error(`Image with type ${type} not found!`);
    }
}

const getAltText = (type: TPropsTypeOfEmptyArrayImage) => {
    //TODO use t here
    switch (type){
        case "frameworks": return 'No Frameworks';
        case "automations": return 'No Automations';
        case "collectors": return 'No Collectors';
        case "documents": return 'No Documents';
        case "reports": return 'No Reports';
        case "risks": return 'No Risk Registers';
        case "analysis": return 'No Analysis';
        case "builder": return 'No Frameworks found!';
        case "quickStart": return 'No quick start packages found!';
        case "organizations": return 'No Organizations';
        case "availableInPaidOnly": return 'Available In Paid Only';
        case "hrManagement": return 'No Employees';
        case "vulnerability": return 'No Targets.';
        case "vendors": return 'No Vendors';
        default: return 'Not found!';
    }
}

export const EmptyArrayImage = ({type, isShowing, text, onClick, menu, withoutAdd, secondaryText}: TProps) => {
    const {t} = useMainTranslation('', {keyPrefix: 'supportPage'});
    const actionEl = useRef<HTMLDivElement>(null);
    const [isOpenMenu, setIsOpenMenu] = useState<boolean>(false);

    const history = useHistory();

    const goToHelp = () => {
        history.push(PATH_LOCAL_HELP);
    };

    return(
        isShowing ?
            <Flex direction={"column"} ai={"center"} jc={"center"} w={'100%'} h={`100%`}>
                <img src={getImage(type)} alt={getAltText(type)}/>
                <Typography color={colors.darkBlueText} variant={'h3'} margin={'41px auto 0 auto'}>
                    {text}
                </Typography>
                <Flex talign={'center'} w={'30%'} ai={'center'} jc={'center'} m={'10px 0 0 0'}>
                    <Typography color={colors.darkBlueText}>
                        {secondaryText}
                    </Typography>
                </Flex>

                <Flex ai={"center"} jc={"center"} w={'300px'} ref={actionEl}>
                    {!withoutAdd && <RoundButton id="actions-button" sx={{width: 56, height: 56}} color={"primary"} size={"large"}
                                                 onClick={onClick ? onClick : () => setIsOpenMenu(true)}>+</RoundButton>}

                    {type === 'availableInPaidOnly' &&
                        <CustomButton
                            variant={'contained'}
                            size={'large'}
                            color={'primary'}
                            onClick={goToHelp}
                            aria-label="Menu header"
                        >
                            {t('Contact Us')}
                        </CustomButton>
                    }
                </Flex>
                {menu &&
                <Menu id="actions-menu"
                      MenuListProps={{
                          'aria-labelledby': 'actions-button',
                      }}
                      anchorEl={actionEl.current}
                      anchorOrigin={{
                          vertical: 'center',
                          horizontal: 'center'
                      }}
                      transformOrigin={{
                          vertical: 'top',
                          horizontal: 'center'
                      }}
                      style={{
                          minWidth: actionEl.current?.offsetWidth,
                      }}

                      open={isOpenMenu}
                      onClose={() => setIsOpenMenu(false)}
                      TransitionComponent={Fade}
                      onClick={e => {e.stopPropagation()}}>
                    {menu.map((e, index) => {
                        return(
                            <MenuItem key={index + ' ' + e.title}
                                style={{minWidth: actionEl.current?.offsetWidth}}
                                onClick={() => {
                                    e.onClick();
                                    setIsOpenMenu(false);
                                }}
                            >{e.title}
                            </MenuItem>
                        )
                    })}
                </Menu>
                }
            </Flex>
            :
            <></>
    )
}