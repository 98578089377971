import {addInfoSnack} from "../../../newDomain/barsEnvironment/snack/store/slice";
import {store} from "../../redux";

export const makeFirstLetterUpper = (text: string) => text.split('').map((e, id) => id === 0 ? e.toUpperCase() : e).join('');

export const copyTextToClipBoard = (text: string, snack?: string) => {
    navigator.clipboard.writeText(text);
    snack && store.dispatch(addInfoSnack(snack));
}

export function validateNotEmpty(str: string): boolean {
    return str.trim().length > 0;
}

export function validateEmail(email: string): boolean {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
}

export function validatePassword(password: string): boolean {
    var re = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})");
    return re.test(password);
}

export const getName = (firstName: string, lastName: string, middleName?: string): string => {
    if (middleName) {
        return `${firstName ?? ''} ${middleName?.trim() ?? ''} ${lastName ?? ''}`.split(' ').filter(e => e !== '').join(' ');
    } else {
        return `${firstName ?? ''} ${lastName ?? ''}`.split(' ').filter(e => e !== '').join(' ');
    }
};

export const toInitials = (str?: string | null): string => {
    return (str ?? 'N N').split(' ')
        .filter(x => x !== '')
        .filter((_ , index)  => index < 2 )
        .map(x => x.charAt(0).toUpperCase())
        .join('')
}

export const getAvatarInitials = (name: string, symbols: number = 2): string => {
    if(!name.length) return '';
    const words = name.trim().split(' ').filter(e => e !== '');
    const firstChars = words.map(e => e.charAt(0));

    if (firstChars.length >= symbols) {
        //abra kadabra -> AK
        return firstChars.slice(0, symbols).join('').toUpperCase();
    } else {
        //abrakadabra -> AB
        return words[0]?.slice(0, symbols).toUpperCase() || '';
    }
}

export function downloadAsTXT(filename: string, text: string) {
    var element = document.createElement('a');
    element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(text));
    element.setAttribute('download', filename);

    element.style.display = 'none';
    document.body.appendChild(element);

    element.click();

    document.body.removeChild(element);
}
