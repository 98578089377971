import {Popover, Typography} from "@mui/material";
import {CSSProperties, FC, ReactNode, useRef, useState} from "react";
import styled from 'styled-components';
import {TypographyTypeMap} from "@mui/material/Typography/Typography";


const TTypo = styled(Typography)<{ fontSize?: string; }>`

  // @media screen and (min-width: 2881px) {
    //    font-size: ${props => props.fontSize ? `${parseInt(props.fontSize) * 1.8}${props.fontSize.split(parseInt(props.fontSize).toString())[1]}` : "16px"} !important;
  // }
  //
  // @media screen and (max-width: 2880px) {
    //    font-size: ${props => props.fontSize ? `${parseInt(props.fontSize) * 1.5}${props.fontSize.split(parseInt(props.fontSize).toString())[1]}` : "16px"} !important;
  // }
  //
  // @media screen and (max-width: 2500px) {
    //    font-size: ${props => props.fontSize ? `${parseInt(props.fontSize) * 1.4}${props.fontSize.split(parseInt(props.fontSize).toString())[1]}` : "16px"} !important;
  // }
  //
  // @media screen and (max-width: 2400px) {
    //    font-size: ${props => props.fontSize ? `${parseInt(props.fontSize) * 1.15}${props.fontSize.split(parseInt(props.fontSize).toString())[1]}` : "16px"} !important;
  // }
  //
  // @media screen and (max-width: 2100px) {
    //    font-size: ${props => props.fontSize ? `${parseInt(props.fontSize) * 1.1}${props.fontSize.split(parseInt(props.fontSize).toString())[1]}` : "16px"} !important;
  // }
  //
  // @media screen and (max-width: 1920px) {
    //    font-size: ${props => props.fontSize ? `${parseInt(props.fontSize)}${props.fontSize.split(parseInt(props.fontSize).toString())[1]}` : "16px"} !important;
  // }
  //
  // @media screen and (max-width: 1800px) {
    //    font-size: ${props => props.fontSize ? `${parseInt(props.fontSize) * 0.95}${props.fontSize.split(parseInt(props.fontSize).toString())[1]}` : "16px"} !important;
  // }
  //
  // @media screen and (max-width: 1600px) {
    //    font-size: ${props => props.fontSize ? `${parseInt(props.fontSize) * 0.9}${props.fontSize.split(parseInt(props.fontSize).toString())[1]}` : "16px"} !important;
  // }
  //
  // @media screen and (max-width: 1441px) {
    //    font-size: ${props => props.fontSize ? `${parseInt(props.fontSize) * 0.8}${props.fontSize.split(parseInt(props.fontSize).toString())[1]}` : "16px"} !important;
  // }
  //
  // @media screen and (max-width: 960px) {
    //    font-size: ${props => props.fontSize ? `${parseInt(props.fontSize) * 0.7}${props.fontSize.split(parseInt(props.fontSize).toString())[1]}` : "16px"} !important;
  // }
  //
  // @media screen and (max-width: 600px) {
    //    font-size: ${props => props.fontSize ? `${parseInt(props.fontSize) * 0.5}${props.fontSize.split(parseInt(props.fontSize).toString())[1]}` : "16px"} !important;
  // }
  //
  // @media screen and (max-width: 376px) {
    //    font-size: ${props => props.fontSize ? `${parseInt(props.fontSize) * 0.4}${props.fontSize.split(parseInt(props.fontSize).toString())[1]}` : "16px"} !important;
  // }
`;

type PropTypes = {
    fontSize?: string,
    fontWeight?: number,
    variant?: "h1" | "h2" | "h3" | "h4" | "h5" | "h6",
    color?: string,
    userSelect?: "none" | "auto" | "text" | "all" | "contain",
    margin?:string,
    letterSpacing?: string,
    cursor?: string,
    textDecoration?: string,
    children: ReactNode,
    popOverText?: string,

    whiteSpace?: "break-spaces" | "normal" | "nowrap" | "pre" | "pre-line" | "pre-wrap",
    textOverflow?: "clip" | "ellipsis" | "fade",
    disablePopover?: boolean,

    noWrap?: boolean
    align?: TypographyTypeMap["props"]["align"],
    alignSelf?:'stretch' | 'center' | 'start' | 'end' | 'self-start' | 'self-end' | 'flex-start' | 'flex-end',
    shrink?: boolean;
    style?: CSSProperties,
    onClick?: () => void,
    sx?: TypographyTypeMap["props"]['sx']

}

export const Typo: FC<PropTypes> = ({
                                        fontWeight,
                                        fontSize,
                                        variant,
                                        color,
                                        userSelect,
                                        margin,
                                        letterSpacing,
                                        cursor,
                                        textDecoration,
                                        children,
                                        shrink,
                                        whiteSpace,
                                        textOverflow = shrink ? 'ellipsis' : 'clip',
                                        noWrap = shrink,
                                        align,
                                        alignSelf,
                                        disablePopover = undefined,
                                        style,
                                        onClick,
                                        sx,
                                        popOverText,
                                    }: PropTypes) => {

    const anchorEl = useRef<HTMLSpanElement>(null);
    const [open, setOpen] = useState<boolean>(false);

    return (
        <>
            <TTypo fontSize={fontSize}

                   onMouseOver={textOverflow ? () => setOpen(true) : undefined}
                   onMouseLeave={textOverflow ? () => setOpen(false) : undefined}
                   ref={textOverflow ? anchorEl : undefined}
                   aria-owns={textOverflow ? (open ? 'mouse-over-popover' : undefined) : undefined}
                   aria-haspopup={textOverflow ? "true" : undefined}

                   onClick={onClick ? (e) => {e.stopPropagation(); onClick()} : undefined}
                   variant={variant}
                   style={{
                       color,
                       fontWeight,
                       fontSize,
                       userSelect,
                       margin,
                       cursor,
                       textDecoration,
                       letterSpacing,
                       whiteSpace,
                       textOverflow,
                       alignSelf,
                       ...style
                   }}
                   noWrap={noWrap}
                   align={align}
                   sx={sx}
            >{children}</TTypo>

            {!disablePopover && textOverflow && anchorEl && anchorEl.current && (
                anchorEl.current.scrollWidth > anchorEl.current.clientWidth
            ) && (
                <Popover
                    disablePortal
                    id={"mouse-over-popover"}
                    open={open}
                    anchorEl={anchorEl.current}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'left',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'left',
                    }}
                    style={{pointerEvents: 'none'}}
                    onClose={() => setOpen(false)}
                    disableRestoreFocus
                >

                    <Typography
                        variant={"h2"}
                        id={'hiwrhwlewlerglwireguirgwieriwegrir'}
                        style={{
                            fontWeight: 300,
                            fontSize: 16,
                            padding: 15,
                            // zIndex: 3501,
                            maxWidth: '400px',
                            hyphens: 'auto'
                        }}
                    >{popOverText ? popOverText : children}</Typography>
                </Popover>
            )}
        </>
    )
};

Typo.defaultProps = {
    variant: "h2",
    fontSize: "14px",
    fontWeight: 400,
    userSelect: "auto",
    color: "#000000",
    margin:"0",
    cursor:"auto",
    letterSpacing:"normal",
    textDecoration:"none"
};
