import {
    DocumentExecutorModel,
    NewDocDataVariableModel,
    NewDocumentModel,
    ShortDocumentModel
} from "../../../newShared/GQLTypes";
import {TPageInfo} from "../../../newShared/types";
import {
    ACTIONS_COLUMN_KEY,
    CURRENT_STEP_ETA_COLUMN_KEY,
    DOCUMENT_NAME_COLUMN_KEY,
    EXPIRED_ON_COLUMN_KEY,
    LAST_MODIFIED_COLUMN_KEY,
    RECIPIENTS_COLUMN_KEY,
    SENDER_COLUMN_KEY,
    STATUS_COLUMN_KEY,
    WORKSPACE_NAME_COLUMN_KEY
} from "./constants"

export type TDocumentFile = NewDocumentModel;
export type TShortDocument = ShortDocumentModel;
export type TDocumentExecutor = DocumentExecutorModel;

export type TVariable = NewDocDataVariableModel;

export type documentsInitialState = {
    documents: TShortDocument[];
    pageInfo: TPageInfo;
    selectedDocument: {
        document: TDocumentFile | null;
        documentExecutor: TDocumentExecutor | null;
    }
    dialogs: {

    }
    loadings: {
        isLoadingList: boolean;
        isLoadingExact: boolean;
        isLoadingRejectSignStageByStageId: boolean;
        isLoadingRejectApproveStageByStageId: boolean;
        isLoadingFillFillableFieldOnSignStage: boolean;
        isLoadingRestartSignStageByStageIdAndRecipient: boolean;
        isLoadingRestartSignStageByStageIdAndGroupId: boolean;
        isLoadingApproveSignStageByStageId: boolean;
        isLoadingApproveApproveStageByStageId: boolean;
        isLoadingSendToNextStep: boolean;
        isLoadingInvalidate: boolean;
        isLoadingSave: boolean;
    }
}

export const DRAFT_STATUS = "DRAFT";
export const SHARED_DRAFT_STATUS = "SHARED_DRAFT";
export const APPROVAL_STATUS = "APPROVAL";
export const REMARKS_STATUS = "REMARKS";
export const SIGNING_STATUS = "SIGNING";
export const COMPLETED_STATUS = "COMPLETED";
export const TERMINATED_STATUS = "TERMINATED";

//
export const PENDING_STATUS = "PENDING";
export const IN_PROGRESS_STATUS = "IN_PROGRESS";
export const REJECTED_STATUS = "REJECTED";
export const APPROVED_STATUS = "APPROVED";
export const SKIPPED_STATUS = "SKIPPED";


export type TDocumentStatus = typeof DRAFT_STATUS | typeof SHARED_DRAFT_STATUS |
    typeof APPROVAL_STATUS | typeof REMARKS_STATUS | typeof SIGNING_STATUS  |
    typeof COMPLETED_STATUS | typeof TERMINATED_STATUS;

export type TDocumentExecutorStatus = typeof PENDING_STATUS | typeof IN_PROGRESS_STATUS |
    typeof REJECTED_STATUS | typeof APPROVED_STATUS | typeof SKIPPED_STATUS;

export const DocActorStatusVariants: {status: TDocumentStatus, title: string}[] = [
    {status: DRAFT_STATUS, title: 'Draft'},
    {status: SHARED_DRAFT_STATUS, title: 'Shared Draft'},
    {status: APPROVAL_STATUS, title: 'Approval'},
    {status: REMARKS_STATUS, title: 'Remarks'},
    {status: SIGNING_STATUS, title: 'Signing'},
    {status: COMPLETED_STATUS, title: 'Completed'},
    {status: TERMINATED_STATUS, title: 'Terminated'},
];

export type ColumnKeyType =
    typeof DOCUMENT_NAME_COLUMN_KEY |
    typeof LAST_MODIFIED_COLUMN_KEY |
    typeof RECIPIENTS_COLUMN_KEY |
    typeof EXPIRED_ON_COLUMN_KEY |
    typeof STATUS_COLUMN_KEY |
    typeof WORKSPACE_NAME_COLUMN_KEY |
    typeof SENDER_COLUMN_KEY |
    typeof ACTIONS_COLUMN_KEY |
    typeof  CURRENT_STEP_ETA_COLUMN_KEY
    ;


export type ColumnType = {
    key: ColumnKeyType;
    normalizedName: string; // First name
}