import axiosSync from "../../../../newShared/utils/axios/axios";
import {print} from "graphql";
import {gql} from "@apollo/react-hooks";
import {AxiosResponse} from "axios";
import {PATH_SERVER} from "../../../../newShared/constants";
import {
    DocApproverInput,
    GetFullDocumentResponseModel,
    NewDocDataInput,
    NewDocumentActorInput,
    PaginationInputAnyFilter,
    RecipientInput,
    RespShortDocumentWithFilterPagingModel
} from "../../../../newShared/GQLTypes";
import {buildCommonHeader} from "../../../../newShared/utils/builders";
import {NewDocumentExecutorFragment, NewDocumentFragment} from "./fragments";
import {TPageInfo} from "../../../../newShared/types";

export const getShortDocumentsPagingDocs = async (pageInfo: PaginationInputAnyFilter): Promise<RespShortDocumentWithFilterPagingModel> => (
    await axiosSync.then(
        (axios) => axios.post(
            PATH_SERVER,
            {
                query: print(gql`
                    query getShortDocumentsPagingDocs($pageInfo:PaginationInputAnyFilter!){
                        getShortDocumentsPagingDocs(pageInfo:$pageInfo){
                            files {
                                id
                                documentId
                                workspaceId
                                type
                                name
                                folderId
                                status
                                origin
                                version
                                latest
                                createdDate
                                tags
                                lastModified
                                generatedFileId
                                sender
                                workspaceName
                                currentStepEndDate
                                recipients{
                                    role
                                    message
                                    order
                                    actor{
                                        email
                                        firstName
                                        lastName
                                    }
                                    fields{
                                        id
                                        pageId
                                        type
                                        position{
                                            x
                                            y
                                            metaPageHeight
                                        }
                                        size{
                                            width
                                            height
                                        }
                                    }
                                }
                            }
                            pageInfo{
                                total
                                page
                                count
                                sort
                            }
                        }
                    }
                `),
                variables: {
                    pageInfo
                }
            },
            {
                headers: buildCommonHeader()
            }
        )
    ).then((result: AxiosResponse<any>) => {
        return result.data.data.getShortDocumentsPagingDocs;
    })
)

export const getSharedShortDocumentsPagingDocs = async (pageInfo: PaginationInputAnyFilter): Promise<RespShortDocumentWithFilterPagingModel> => (
    await axiosSync.then(
        (axios) => axios.post(
            PATH_SERVER,
            {
                query: print(gql`
                    query getSharedShortDocumentsPagingDocs($pageInfo:PaginationInputAnyFilter!){
                        getSharedShortDocumentsPagingDocs(pageInfo:$pageInfo){
                            files {
                                id
                                documentId
                                workspaceId
                                type
                                name
                                folderId
                                status
                                origin
                                version
                                latest
                                createdDate
                                tags
                                lastModified
                                generatedFileId
                                sender
                                workspaceName
                                currentStepEndDate
                                recipients{
                                    role
                                    message
                                    order
                                    actor{
                                        email
                                        firstName
                                        lastName
                                    }
                                    fields{
                                        id
                                        pageId
                                        type
                                        position{
                                            x
                                            y
                                            metaPageHeight
                                        }
                                        size{
                                            width
                                            height
                                        }
                                    }
                                }
                            }
                            pageInfo{
                                total
                                page
                                count
                                sort
                            }
                        }
                    }
                `),
                variables: {
                    pageInfo
                }
            },
            {
                headers: buildCommonHeader()
            }
        )
    ).then((result: AxiosResponse<any>) => {
        return result.data.data.getSharedShortDocumentsPagingDocs;
    })
)

export const getFullDocumentWithExecutorForDocs = async (documentId: string): Promise<GetFullDocumentResponseModel> => (
    await axiosSync.then(
        (axios) => axios.post(
            PATH_SERVER,
            {
                query: print(gql`
                    query getFullDocumentWithExecutorForDocs($documentId:String!){
                        getFullDocumentWithExecutorForDocs(documentId:$documentId){
                            file{...NewDocumentFragment}
                            executor{...NewDocumentExecutorFragment}
                        }
                    }
                    ${NewDocumentFragment}
                    ${NewDocumentExecutorFragment}
                `),
                variables: {
                    documentId
                }
            },
            {
                headers: buildCommonHeader()
            }
        )
    ).then((result: AxiosResponse<any>) => {
        return result.data.data.getFullDocumentWithExecutorForDocs;
    })
)

export const rejectSignStageByStageId = async(stageId: string, documentId: string): Promise<GetFullDocumentResponseModel> => (
    await axiosSync.then(
        (axios) => axios.post(
            PATH_SERVER,
            {
                query: print(gql`
                    mutation rejectSignStageByStageId($stageId:String!, $documentId: String!){
                      rejectSignStageByStageId(stageId:$stageId, documentId: $documentId){
                        file{...NewDocumentFragment}
                        executor{...NewDocumentExecutorFragment}
                      }
                    }
                    ${NewDocumentFragment}
                    ${NewDocumentExecutorFragment}
                `),
                variables: {
                    stageId,
                    documentId
                }
            },
            {
                headers: buildCommonHeader()
            }
        )
    ).then((result: AxiosResponse<any>) => {
        return result.data.data.rejectSignStageByStageId;
    })
)

export const rejectApproveStageByStageId = async(stageId: string, documentId: string): Promise<GetFullDocumentResponseModel> => (
    await axiosSync.then(
        (axios) => axios.post(
            PATH_SERVER,
            {
                query: print(gql`
                    mutation rejectApproveStageByStageId($stageId:String!, $documentId: String!){
                      rejectApproveStageByStageId(stageId:$stageId, documentId: $documentId){
                        file{...NewDocumentFragment}
                        executor{...NewDocumentExecutorFragment}
                      }
                    }
                    ${NewDocumentFragment}
                    ${NewDocumentExecutorFragment}
                `),
                variables: {
                    stageId,
                    documentId
                }
            },
            {
                headers: buildCommonHeader()
            }
        )
    ).then((result: AxiosResponse<any>) => {
        return result.data.data.rejectApproveStageByStageId;
    })
)


export const fillFillableFieldOnSignStage = async(stageId: string, values: {fieldId: string, value: string}[], documentId: string): Promise<GetFullDocumentResponseModel> => (
    await axiosSync.then(
        (axios) => axios.post(
            PATH_SERVER,
            {
                query: print(gql`
                    mutation fillFillableFieldOnSignStage($values:[FieldIdValueDto!]!, $stageId:String!, $documentId: String!){
                      fillFillableFieldOnSignStage(values:$values, stageId:$stageId, documentId: $documentId){
                        file{...NewDocumentFragment}
                        executor{...NewDocumentExecutorFragment}
                      }
                    }
                    ${NewDocumentFragment}
                    ${NewDocumentExecutorFragment}
                `),
                variables: {
                    stageId,
                    values,
                    documentId
                }
            },
            {
                headers: buildCommonHeader()
            }
        )
    ).then((result: AxiosResponse<any>) => {
        return result.data.data.fillFillableFieldOnSignStage;
    })
)

export const approveSignStageByStageId = async(stageId: string, documentId: string): Promise<GetFullDocumentResponseModel> => (
    await axiosSync.then(
        (axios) => axios.post(
            PATH_SERVER,
            {
                query: print(gql`
                    mutation approveSignStageByStageId($stageId:String!, $documentId: String!){
                      approveSignStageByStageId(stageId:$stageId, documentId: $documentId){
                        file{...NewDocumentFragment}
                        executor{...NewDocumentExecutorFragment}
                      }
                    }
                    ${NewDocumentFragment}
                    ${NewDocumentExecutorFragment}
                `),
                variables: {
                    stageId,
                    documentId
                }
            },
            {
                headers: buildCommonHeader()
            }
        )
    ).then((result: AxiosResponse<any>) => {
        return result.data.data.approveSignStageByStageId;
    })
)

export const approveApproveStageByStageId = async(stageId: string, documentId: string): Promise<GetFullDocumentResponseModel> => (
    await axiosSync.then(
        (axios) => axios.post(
            PATH_SERVER,
            {
                query: print(gql`
                    mutation approveApproveStageByStageId($stageId:String!, $documentId: String!){
                      approveApproveStageByStageId(stageId:$stageId, documentId: $documentId){
                        file{...NewDocumentFragment}
                        executor{...NewDocumentExecutorFragment}
                      }
                    }
                    ${NewDocumentFragment}
                    ${NewDocumentExecutorFragment}
                `),
                variables: {
                    stageId,
                    documentId
                }
            },
            {
                headers: buildCommonHeader()
            }
        )
    ).then((result: AxiosResponse<any>) => {
        return result.data.data.approveApproveStageByStageId;
    })
)


export const updateFullDocument = async(documentId: string, data: NewDocDataInput, name: string, recipients: RecipientInput[], editors: NewDocumentActorInput[], approvers: DocApproverInput[]): Promise<GetFullDocumentResponseModel> => (
    await axiosSync.then(
        (axios) => axios.post(
            PATH_SERVER,
            {
                query: print(gql`
                    mutation updateFullDocumentByDocs($documentId: String!, $data: NewDocDataInput!, $name: String!, $recipients: [RecipientInput!]!, $editors: [NewDocumentActorInput!]!, $approvers: [DocApproverInput!]!){
                      updateFullDocumentByDocs(data:$data, name:$name, recipients:$recipients, editors:$editors, approvers:$approvers, documentId:$documentId){
                        file{...NewDocumentFragment}
                        executor{...NewDocumentExecutorFragment}
                      }
                    }
                    ${NewDocumentFragment}
                    ${NewDocumentExecutorFragment}
                `),
                variables: {
                    documentId,
                    data,
                    name,
                    recipients,
                    editors,
                    approvers
                }
            },
            {
                headers: buildCommonHeader()
            }
        )
    ).then((result: AxiosResponse<any>) => {
        return result.data.data.updateFullDocumentByDocs;
    })
)


//getAutocompleteSender
export const getAutocompleteSender = async(data: {search: string, page: number, count: number}, signal?: AbortSignal): Promise<{values: string[], pageInfo: TPageInfo}> => (
    await axiosSync.then(
        (axios) => axios.post(
            PATH_SERVER,
            {
                query: print(gql`
                    query getDocsMyDocumentsAutocompleteSender($page: Float!, $count: Float!, $nameLike: String!){
                      getDocsMyDocumentsAutocompleteSender(page:$page, count:$count, nameLike: $nameLike){
                        values
                        pageInfo{
                            page
                            count
                            total
                        }
                      }
                    }
                `),
                variables: {
                    page: data.page, count: data.count, nameLike: data.search
                },
                signal
            },
            {
                headers: buildCommonHeader()
            }
        )
    ).then((result: AxiosResponse<any>) => {
        return result.data.data.getDocsMyDocumentsAutocompleteSender;
    })
)

//getDocsMyDocumentsAutocompleteWorkspaceNames
export const getDocsMyDocumentsAutocompleteWorkspaceNames = async(data: {search: string, page: number, count: number}, signal?: AbortSignal): Promise<{values: {workspaceId: string, workspaceName: string}[], pageInfo: TPageInfo}> => (
    await axiosSync.then(
        (axios) => axios.post(
            PATH_SERVER,
            {
                query: print(gql`
                    query getDocsMyDocumentsAutocompleteWorkspaceNames($page: Float!, $count: Float!, $nameLike: String!){
                      getDocsMyDocumentsAutocompleteWorkspaceNames(page:$page, count:$count, nameLike: $nameLike){
                        values{
                            workspaceId
                            workspaceName
                        }
                        pageInfo{
                            page
                            count
                            total
                        }
                      }
                    }
                `),
                variables: {
                    page: data.page, count: data.count, nameLike: data.search
                },
                signal
            },
            {
                headers: buildCommonHeader()
            }
        )
    ).then((result: AxiosResponse<any>) => {
        return result.data.data.getDocsMyDocumentsAutocompleteWorkspaceNames;
    })
)

//getDocsSharedDocumentsAutocompleteWorkspaceNames
export const getDocsSharedDocumentsAutocompleteWorkspaceNames = async(data: {search: string, page: number, count: number}, signal?: AbortSignal): Promise<{values: {workspaceId: string, workspaceName: string}[], pageInfo: TPageInfo}> => (
    await axiosSync.then(
        (axios) => axios.post(
            PATH_SERVER,
            {
                query: print(gql`
                    query getDocsSharedDocumentsAutocompleteWorkspaceNames($page: Float!, $count: Float!, $nameLike: String!){
                      getDocsSharedDocumentsAutocompleteWorkspaceNames(page:$page, count:$count, nameLike: $nameLike){
                        values{
                            workspaceId
                            workspaceName
                        }
                        pageInfo{
                            page
                            count
                            total
                        }
                      }
                    }
                `),
                variables: {
                    page: data.page, count: data.count, nameLike: data.search
                },
                signal
            },
            {
                headers: buildCommonHeader()
            }
        )
    ).then((result: AxiosResponse<any>) => {
        return result.data.data.getDocsSharedDocumentsAutocompleteWorkspaceNames;
    })
)