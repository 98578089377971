import * as React from "react";
import {ComponentProps, FC, forwardRef} from "react";
import {Button, Icon, SvgIcon} from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import {LoadingButton as MuiLoadingButton, LoadingButtonProps} from "@mui/lab";
import {useMainTranslation} from "../../../hooks/useMainTranslationHooks/useMainTranslation";


type TIconTextButton = ComponentProps<typeof Button> & {
    icon: ComponentProps<typeof SvgIcon>
}

export const IconTextButton: FC<TIconTextButton> = ({children, icon,  ...props}) => {
    return (
        <Button startIcon={icon} {...props} >
            {children}
        </Button>
    )
}

type TDropdownButton = ComponentProps<typeof Button> & {
    icon?: ComponentProps<typeof SvgIcon>;
    open: boolean;

}

export const DropdownButton: FC<TDropdownButton> = ({children, icon, open , ...props}) => {
    return (
        <>
            <Button startIcon={icon}
                    endIcon={open ? <KeyboardArrowUpIcon/> : <KeyboardArrowDownIcon />}
                    style={{
                        textTransform: 'none',
                        color: (props.variant === 'text' && !props.disabled) ? '#343440' : 'undefined',
                    }}
                    {...props}
            >
                {children}
            </Button>
        </>


    )
}

export const CommonIconButton: FC<TIconTextButton> = ({icon,  ...props}) => {
    return (
        <Button {...props} >
            {icon}
        </Button>
    )
}

export const LoadingButton = forwardRef<HTMLButtonElement, LoadingButtonProps>(({children, loading, startIcon, loadingPosition, ...props}, ref) => {
    const {revDir} = useMainTranslation('', {keyPrefix: 'CommonComponents'});

    return (
        <MuiLoadingButton loading={loading} variant={"contained"} loadingPosition={loadingPosition ?? (startIcon || loading) ? !revDir ? 'start' : 'end' : undefined}
                          startIcon={startIcon ?? (loading ? <Icon/> : undefined)} ref={ref}

                          {...props}
        >
            {children}
        </MuiLoadingButton>
    )
})

