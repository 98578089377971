import {documentsInitialState, TShortDocument} from "../types";
import {createSelector, createSlice} from "@reduxjs/toolkit";
import {AppState} from "../../../../newShared/redux/rootReducer";
import {
    ApproveApproveStageByStageId,
    ApproveSignStageByStageId,
    FillFillableFieldOnSignStage,
    GetFullDocumentWithExecutorForDocs,
    GetSharedShortDocumentsPagingDocs,
    GetShortDocumentsPagingDocs,
    RejectApproveStageByStageId,
    RejectSignStageByStageId,
    UpdateFullDocument
} from "./actions";
import {setPaginationInStore} from "../../../../newShared/hooks/useScroll/helpers";
import {convertFullDocumentToSmallDocument} from "../helpers";

export const initialState: documentsInitialState = {
    documents: [],
    pageInfo: {
        page: 0,
        total: 1,
        count: 0,
    },
    selectedDocument: {
        document: null,
        documentExecutor: null
    },
    dialogs: {

    },
    loadings: {
        isLoadingExact: false,
        isLoadingList: false,
        isLoadingRejectSignStageByStageId:  false,
        isLoadingRejectApproveStageByStageId:  false,
        isLoadingFillFillableFieldOnSignStage:  false,
        isLoadingRestartSignStageByStageIdAndRecipient:  false,
        isLoadingRestartSignStageByStageIdAndGroupId:  false,
        isLoadingApproveSignStageByStageId:  false,
        isLoadingApproveApproveStageByStageId:  false,
        isLoadingSendToNextStep: false,
        isLoadingInvalidate: false,
        isLoadingSave: false
    }
}

export const DocumentsSlice = createSlice({
    name: 'documents',
    initialState,
    reducers: {
        clearList: (slice) => {
            slice.documents = [];
            slice.pageInfo = initialState.pageInfo;
        },
        deselectDoc: (slice) => {
            slice.selectedDocument = {
                document: null,
                documentExecutor: null
            }
        }
    },
    extraReducers: (builder) => {
        builder
            //GetShortDocumentsPagingDocs
            .addCase(GetShortDocumentsPagingDocs.pending, (slice) => {
                slice.loadings.isLoadingList = true;
            })
            .addCase(GetShortDocumentsPagingDocs.rejected, (slice) => {
                slice.loadings.isLoadingList = false;
            })
            .addCase(GetShortDocumentsPagingDocs.fulfilled, (slice, {payload}) => {
                slice.loadings.isLoadingList = false;

                const {array, pageInfo} = setPaginationInStore<TShortDocument>(
                    slice.pageInfo,
                    slice.documents,
                    payload.pageInfo,
                    payload.files,
                    false
                );
                slice.documents = array.filter(e => e !== null) as TShortDocument[];
                slice.pageInfo = pageInfo;
            })
        //GetSharedShortDocumentsPagingDocs
            .addCase(GetSharedShortDocumentsPagingDocs.pending, (slice) => {
                slice.loadings.isLoadingList = true;
            })
            .addCase(GetSharedShortDocumentsPagingDocs.rejected, (slice) => {
                slice.loadings.isLoadingList = false;
            })
            .addCase(GetSharedShortDocumentsPagingDocs.fulfilled, (slice, {payload}) => {
                slice.loadings.isLoadingList = false;

                const {array, pageInfo} = setPaginationInStore<TShortDocument>(
                    slice.pageInfo,
                    slice.documents,
                    payload.pageInfo,
                    payload.files,
                    false
                );
                slice.documents = array.filter(e => e !== null) as TShortDocument[];
                slice.pageInfo = pageInfo;
            })
        //GetFullDocumentWithExecutorForDocs
            .addCase(GetFullDocumentWithExecutorForDocs.pending, (slice) => {
                slice.loadings.isLoadingExact = true;
            })
            .addCase(GetFullDocumentWithExecutorForDocs.rejected, (slice) => {
                slice.loadings.isLoadingExact = false;
            })
            .addCase(GetFullDocumentWithExecutorForDocs.fulfilled, (slice, {payload}) => {
                slice.loadings.isLoadingExact = false;
                if(payload.file){
                    slice.selectedDocument = {
                        document: payload.file,
                        documentExecutor: payload.executor ?? null
                    }
                }
            })
        //RejectSignStageByStageId
            .addCase(RejectSignStageByStageId.pending, (slice) => {
                slice.loadings.isLoadingRejectSignStageByStageId = true;
            })
            .addCase(RejectSignStageByStageId.rejected, (slice) => {
                slice.loadings.isLoadingRejectSignStageByStageId = false;
            })
            .addCase(RejectSignStageByStageId.fulfilled, (slice, {payload}) => {
                slice.loadings.isLoadingRejectSignStageByStageId = false;
                if(payload.file){
                    // slice.selectedDocument = {
                    //     document: payload.file,
                    //     documentExecutor: payload.executor ?? null
                    // }
                    slice.documents = slice.documents
                        .filter(e => e !== null)
                        .map(e => e!.documentId === payload.file!.documentId ? convertFullDocumentToSmallDocument(payload.file!) : e)
                    slice.selectedDocument = {document: null, documentExecutor: null};
                }
            })
        //RejectApproveStageByStageId
            .addCase(RejectApproveStageByStageId.pending, (slice) => {
                slice.loadings.isLoadingRejectApproveStageByStageId = true;
            })
            .addCase(RejectApproveStageByStageId.rejected, (slice) => {
                slice.loadings.isLoadingRejectApproveStageByStageId = false;
            })
            .addCase(RejectApproveStageByStageId.fulfilled, (slice, {payload}) => {
                slice.loadings.isLoadingRejectApproveStageByStageId = false;
                if(payload.file){
                    // slice.selectedDocument = {
                    //     document: payload.file,
                    //     documentExecutor: payload.executor ?? null
                    // }
                    slice.documents = slice.documents.filter(e => e !== null).map(e => e!.documentId === payload.file!.documentId ? convertFullDocumentToSmallDocument(payload.file!) : e)
                    slice.selectedDocument = {document: null, documentExecutor: null};
                }
            })
        //FillFillableFieldOnSignStage
            .addCase(FillFillableFieldOnSignStage.pending, (slice) => {
                slice.loadings.isLoadingFillFillableFieldOnSignStage = true;
            })
            .addCase(FillFillableFieldOnSignStage.rejected, (slice) => {
                slice.loadings.isLoadingFillFillableFieldOnSignStage = false;
            })
            .addCase(FillFillableFieldOnSignStage.fulfilled, (slice, {payload}) => {
                slice.loadings.isLoadingFillFillableFieldOnSignStage = false;
                if(payload && payload.file){
                    slice.selectedDocument = {
                        document: payload.file,
                        documentExecutor: payload.executor ?? null
                    }
                    slice.documents = slice.documents.filter(e => e !== null).map(e => e!.documentId === payload.file!.documentId ? convertFullDocumentToSmallDocument(payload.file!) : e)
                }
            })
        // //RestartSignStageByStageIdAndRecipient
        //     .addCase(RestartSignStageByStageIdAndRecipient.pending, (slice) => {
        //         slice.loadings.isLoadingRestartSignStageByStageIdAndRecipient = true;
        //     })
        //     .addCase(RestartSignStageByStageIdAndRecipient.rejected, (slice) => {
        //         slice.loadings.isLoadingRestartSignStageByStageIdAndRecipient = false;
        //     })
        //     .addCase(RestartSignStageByStageIdAndRecipient.fulfilled, (slice, {payload}) => {
        //         slice.loadings.isLoadingRestartSignStageByStageIdAndRecipient = false;
        //     })
        // //RestartSignStageByStageIdAndGroupId
        //     .addCase(RestartSignStageByStageIdAndGroupId.pending, (slice) => {
        //         slice.loadings.isLoadingRestartSignStageByStageIdAndGroupId = true;
        //     })
        //     .addCase(RestartSignStageByStageIdAndGroupId.rejected, (slice) => {
        //         slice.loadings.isLoadingRestartSignStageByStageIdAndGroupId = false;
        //     })
        //     .addCase(RestartSignStageByStageIdAndGroupId.fulfilled, (slice, {payload}) => {
        //         slice.loadings.isLoadingRestartSignStageByStageIdAndGroupId = false;
        //     })
        //ApproveSignStageByStageId
            .addCase(ApproveSignStageByStageId.pending, (slice) => {
                slice.loadings.isLoadingApproveSignStageByStageId = true;
            })
            .addCase(ApproveSignStageByStageId.rejected, (slice) => {
                slice.loadings.isLoadingApproveSignStageByStageId = false;
            })
            .addCase(ApproveSignStageByStageId.fulfilled, (slice, {payload}) => {
                slice.loadings.isLoadingApproveSignStageByStageId = false;
                if(payload.file){
                    // slice.selectedDocument = {
                    //     document: payload.file,
                    //     documentExecutor: payload.executor ?? null
                    // }
                    slice.documents = slice.documents.filter(e => e !== null).map(e => e!.documentId === payload.file!.documentId ? convertFullDocumentToSmallDocument(payload.file!) : e)
                    slice.selectedDocument = {document: null, documentExecutor: null};
                }
            })
        //ApproveApproveStageByStageId
            .addCase(ApproveApproveStageByStageId.pending, (slice) => {
                slice.loadings.isLoadingApproveApproveStageByStageId = true;
            })
            .addCase(ApproveApproveStageByStageId.rejected, (slice) => {
                slice.loadings.isLoadingApproveApproveStageByStageId = false;
            })
            .addCase(ApproveApproveStageByStageId.fulfilled, (slice, {payload}) => {
                slice.loadings.isLoadingApproveApproveStageByStageId = false;
                if(payload.file){
                    // slice.selectedDocument = {
                    //     document: payload.file,
                    //     documentExecutor: payload.executor ?? null
                    // }
                    slice.documents = slice.documents.filter(e => e !== null).map(e => e!.documentId === payload.file!.documentId ? convertFullDocumentToSmallDocument(payload.file!) : e)
                    slice.selectedDocument = {document: null, documentExecutor: null};
                }
            })
        // //SendToNextStepByStageId
        //     .addCase(SendToNextStepByStageId.pending, (slice) => {
        //         slice.loadings.isLoadingSendToNextStep = true;
        //     })
        //     .addCase(SendToNextStepByStageId.rejected, (slice) => {
        //         slice.loadings.isLoadingSendToNextStep = false;
        //     })
        //     .addCase(SendToNextStepByStageId.fulfilled, (slice, {payload}) => {
        //         slice.loadings.isLoadingSendToNextStep = false;
        //     })
        // //InvalidateDocument
        //     .addCase(InvalidateDocument.pending, (slice) => {
        //         slice.loadings.isLoadingInvalidate = true;
        //     })
        //     .addCase(InvalidateDocument.rejected, (slice) => {
        //         slice.loadings.isLoadingInvalidate = false;
        //     })
        //     .addCase(InvalidateDocument.fulfilled, (slice, {payload}) => {
        //         slice.loadings.isLoadingInvalidate = false;
        //     })
        //UpdateFullDocument
            .addCase(UpdateFullDocument.pending, (slice) => {
                slice.loadings.isLoadingSave = true;
            })
            .addCase(UpdateFullDocument.rejected, (slice) => {
                slice.loadings.isLoadingSave = false;
            })
            .addCase(UpdateFullDocument.fulfilled, (slice, {payload}) => {
                slice.loadings.isLoadingSave = false;
                if(payload.file){
                    slice.selectedDocument = {
                        document: payload.file,
                        documentExecutor: payload.executor ?? null
                    }
                    slice.documents = slice.documents.filter(e => e !== null).map(e => e!.documentId === payload.file!.documentId ? convertFullDocumentToSmallDocument(payload.file!) : e)
                }
            })
    }
});

export const DocumentsReducer = DocumentsSlice.reducer;

export const {
    clearList,
    deselectDoc
} = DocumentsSlice.actions;
//documents
const selectSelf = (state: AppState):documentsInitialState => state.documents as documentsInitialState;

export const loadings = createSelector(selectSelf, state => state.loadings);
export const documents = createSelector(selectSelf, state => state.documents);
export const pageInfo = createSelector(selectSelf, state => state.pageInfo);
export const selectedDocument = createSelector(selectSelf, state => state.selectedDocument);
export const dialogs = createSelector(selectSelf, state => state.dialogs);