import {createAsyncThunk} from "@reduxjs/toolkit";
import {
    DocApproverInput,
    NewDocDataInput,
    NewDocumentActorInput,
    PaginationInputAnyFilter,
    RecipientInput
} from "../../../../newShared/GQLTypes";
import {
    approveApproveStageByStageId,
    approveSignStageByStageId,
    fillFillableFieldOnSignStage,
    getFullDocumentWithExecutorForDocs,
    getSharedShortDocumentsPagingDocs,
    getShortDocumentsPagingDocs,
    rejectApproveStageByStageId,
    rejectSignStageByStageId,
    updateFullDocument
} from "../api";
import {addInfoSnack} from "../../../barsEnvironment/snack/store/slice";
import {b64toBlob} from "../../../../newShared/utils/base64/base64";
import {saveAs} from "file-saver";
import {downloadImageInEditorDocsApi} from "../../../../newShared/components/editorUnion/api";

export const GetShortDocumentsPagingDocs = createAsyncThunk(
    'Documents/GetShortDocumentsPagingDocs',
    async (pageInfo: PaginationInputAnyFilter) => {
        return await getShortDocumentsPagingDocs(pageInfo);
    }
);

export const GetSharedShortDocumentsPagingDocs = createAsyncThunk(
    'Documents/getSharedShortDocumentsPagingDocs',
    async (pageInfo: PaginationInputAnyFilter) => {
        return await getSharedShortDocumentsPagingDocs(pageInfo);
    }
);

export const GetFullDocumentWithExecutorForDocs = createAsyncThunk(
    'Documents/getFullDocumentWithExecutorForDocs',
    async (documentId: string) => {
        return await getFullDocumentWithExecutorForDocs(documentId);
    }
);

export const RejectSignStageByStageId = createAsyncThunk(
    'Documents/rejectSignStageByStageId',
    async ({stageId, documentId}: {stageId: string, documentId: string}, {dispatch}) => {
        const resp = await rejectSignStageByStageId(stageId, documentId);

        dispatch(addInfoSnack('Reject sign stage success'))
        return resp;
    }
);

export const RejectApproveStageByStageId = createAsyncThunk(
    'Documents/rejectApproveStageByStageId',
    async ({stageId, documentId}: {stageId: string, documentId: string}, {dispatch}) => {
        const resp = await rejectApproveStageByStageId(stageId, documentId);

        dispatch(addInfoSnack('Reject approve stage success'))
        return resp;
    }
);


export const FillFillableFieldOnSignStage = createAsyncThunk(
    'Documents/fillFillableFieldOnSignStage',
    async (data: {stageId: string, values: {fieldId: string, value: string}[], onReject: () => void, documentId: string}, {dispatch}) => {
        try{
            const resp = await fillFillableFieldOnSignStage(data.stageId, data.values, data.documentId);

            return resp
        }catch (ex){
            data.onReject()
        }
    }
);

export const ApproveSignStageByStageId = createAsyncThunk(
    'Documents/approveSignStageByStageId',
    async ({stageId, documentId}: {stageId: string, documentId: string}, {dispatch}) => {
        const resp = await approveSignStageByStageId(stageId, documentId);

        dispatch(addInfoSnack('Stage completed'))
        return resp;
    }
);

export const ApproveApproveStageByStageId = createAsyncThunk(
    'Documents/approveApproveStageByStageId',
    async ({stageId, documentId}: {stageId: string, documentId: string}, {dispatch}) => {
        const resp = await approveApproveStageByStageId(stageId, documentId);

        dispatch(addInfoSnack('Document approved'))
        return resp;
    }
);

//updateFullDocument
export const UpdateFullDocument = createAsyncThunk(
    'Documents/updateFullDocument',
    async (data: {documentId: string, data: NewDocDataInput, name: string, recipients: RecipientInput[], editors: NewDocumentActorInput[], approvers: DocApproverInput[]}, {dispatch}) => {
        const resp = await updateFullDocument(data.documentId, data.data, data.name, data.recipients, data.editors, data.approvers);

        dispatch(addInfoSnack('Document updated'))
        return resp;
    }
);

export const DownloadFile = createAsyncThunk(
    'Documents/downloadFile',
    async (data: {fileId: string, fileName: string}, {dispatch, getState, rejectWithValue}) => {

        const resp = await downloadImageInEditorDocsApi({id: data.fileId});
        resp && saveAs(b64toBlob(resp.file), data.fileName)
        return {}
    }
);