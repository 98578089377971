//types
import {TMenuItem} from "./types";

//icons
// import {ReactComponent as ReportsIcon} from "../../../newShared/images/menu/svg-optimized/chart-box-outline.svg";
// import {ReactComponent as InboxIcon} from "../../../newShared/images/menu/inbox.svg";
import {
    ReactComponent as DocumentsIcon
} from "../../../newShared/images/menu/svg-optimized/file-document-multiple-outline.svg"; // fill
//
// import {PATH_LOCAL_SPACE} from "../../../newShared/constants";
import {DOCUMENTS_ROOT, MY_DOCUMENTS_PATH, SHARED_DOCUMENTS_PATH} from "../../components/documents/constants";


// const workerSpace: TMenuItem = {title: 'Staff (Deprecated)', path: PATH_LOCAL_SPACE, icon: ReportsIcon, child: []};
const myDocuments: TMenuItem = {title: 'My documents', path: MY_DOCUMENTS_PATH, child: []};
const sharedDocuments: TMenuItem = {title: 'Shared documents', path: SHARED_DOCUMENTS_PATH, child: []};
// const inboxSpace: TMenuItem = {title: 'Inbox', path: PATH_LOCAL_INBOX, icon: InboxIcon, child: []};
const documents: TMenuItem = {title: 'Documents', path: DOCUMENTS_ROOT, icon: DocumentsIcon, child: [myDocuments, sharedDocuments]};

export const leftMenuItems: TMenuItem[] = [
    documents,
    // workerSpace,
    // inboxSpace,
]
