import {FC} from "react";
import {
    Button,
    Dialog,
    DialogActions,
    DialogTitle,
    FormControlLabel,
    List,
    ListItem,
    ListItemAvatar,
    ListItemIcon,
    Radio
} from "@mui/material";
import ReactCountryFlag from "react-country-flag"
import {SUPPORTED_LANGUAGES} from "../../constants";
import {useLanguageChangeLogic} from "./hook/useLanguageChangeLogic";
import {ChangeLanguagePropTypes} from "./types";

export const LanguageChangeDialog: FC<ChangeLanguagePropTypes> = (props: ChangeLanguagePropTypes) => {
    const {close, onSubmit, isOpen, setInnerLanguage, innerLanguage} = useLanguageChangeLogic(props);

    return(
        <Dialog onClose={close} open={isOpen}>
            <DialogTitle>Change language</DialogTitle>
            <List sx={{ pt: 0 }}>
                {SUPPORTED_LANGUAGES.map((e) => (
                    <ListItem onClick={() => setInnerLanguage(e.code)} key={e.code}>
                        <ListItemIcon>
                            <FormControlLabel value={e.code === innerLanguage} control={<Radio />} label={e.name} checked={innerLanguage === e.code} />
                        </ListItemIcon>
                        <ListItemAvatar>
                            <ReactCountryFlag countryCode={e.countryCode.toUpperCase()} svg style={{fontSize: '2em', lineHeight: '2em'}}/>
                        </ListItemAvatar>
                    </ListItem>
                ))}
            </List>
            <DialogActions>
                <Button variant={'text'} onClick={close} sx={{textTransform: 'none'}}>{'Cancel'}</Button>
                <Button onClick={onSubmit}>{'Confirm'}</Button>
            </DialogActions>
        </Dialog>
    )
}