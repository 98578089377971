import {
    AvatarGroup,
    Box,
    Button,
    IconButton,
    Menu,
    styled,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow
} from "@mui/material";
import colors from "../../../../../src/newShared/theme/colors";
import {
    TypographyBody2,
    TypographyBodySmall,
    TypographySubtitle2
} from "../../../../../src/newShared/components/Inputs/styled";
import {CommonAvatar} from "../../../../../src/newShared/components/Basic/CommonAvatar";


export const InboxTableContainer = styled(TableContainer)`
  margin: 16px 0;
  width: 100%;
  box-shadow: 0 1px 3px rgba(16, 24, 40, 0.1), 0 1px 2px rgba(16, 24, 40, 0.06);
  border-radius: 8px !important;
  border: 1px solid #EAECF0;
  height: 100%;
  flex-grow: 1;
  overflow: auto;
`;

export const InboxTable = styled(Table)``;
InboxTable.defaultProps = {
    stickyHeader: true,
}

export const InboxTableHead = styled(TableHead)`
  background-color: ${colors.backgrounds.grey_light};
`;

export const InboxTotalTableRow = styled(TableRow)`
  & .MuiTableCell-root {
    background-color: ${colors.backgrounds.grey_light};
    width: 100%;
    padding-top: 6px;
    padding-bottom: 6px;
}
`;

export const InboxTableRow = styled(TableRow)`
  & .MuiTableCell-root {
    background-color: ${colors.backgrounds.grey_light}
}
`;

export const InboxTableCell = styled(TableCell)`
  & p {
    white-space: nowrap
  }
`;

export const InboxTotalTypography = styled(TypographySubtitle2)`
    color: ${colors.text.grey};
`;

export const InboxTableTypography = styled(TypographyBody2)`
    color: ${colors.text.grey_dark};
`;

export const InboxTableDeleteTypography = styled(TypographyBody2)`
    color: ${colors.text.critical};
`;

export const InboxActionsTypography = styled(TypographyBody2)`
    color: ${colors.primary.blue};
`;

//TableBody
export const InboxTableBody = styled(TableBody)`
  & .MuiTableRow-root:last-child td {
    border-bottom: 1px solid ${colors.primary.disable};
`;

export const ConfigTableCell = styled(TableCell)`
  padding: 10.5px 16px;
`;

export const StatusTableCellContainer = styled(Box)(() => ({
    display: 'flex',
    alignItems: "center",
}));
export const StatusRoundBox = styled(Box)(({revdir, color}: {revdir: boolean, color?: string}) => ({
    width: '4px',
    height: "4px",
    backgroundColor: color || colors.primary.blue,
    marginRight: !revdir ? "4px" : undefined,
    marginLeft: revdir ? "4px" : undefined,
}));

export const StatusTypography = styled(TypographyBodySmall)(({color}: {color?: string}) => ({
    color: color || colors.primary.blue,
    fontWeight: 500,
}))

StatusTypography.defaultProps = {
    noWrap: true,
}

export const ActionIconButton = styled(IconButton)(({revdir}: {revdir?: boolean}) => ({
    margin: !revdir ? '0 0 0 16px' : '0 16px 0 0',
}));
ActionIconButton.defaultProps = {
    size: 'small',
    color: 'primary',
}

export const InboxStageRowActionMenu = styled(Menu)(() => ({
    '& .MuiMenu-list': {
        padding: '16px'
    },
    '& .MuiMenuItem-root': {
        margin: '0 auto',
        borderRadius: '4px',
        '&:hover': {
            backgroundColor: '#F2F4F7'
        }
    }
}));

InboxStageRowActionMenu.defaultProps = {
    MenuListProps: {'aria-labelledby': 'long-button'},
    slotProps: {
        paper: {
            style: {
                boxShadow: '0px 8px 16px rgba(125, 151, 174, 0.08), 0px 2px 6px rgba(125, 151, 174, 0.02), 0px 0px 1px rgba(125, 151, 174, 0.02)',
                borderRadius: '8px',
                border: '1px solid #F2F4F7'
            },
        }
    },
    // PaperProps: {
    //     style: {
    //         boxShadow: '0px 8px 16px rgba(125, 151, 174, 0.08), 0px 2px 6px rgba(125, 151, 174, 0.02), 0px 0px 1px rgba(125, 151, 174, 0.02)',
    //         borderRadius: '8px',
    //         border: '1px solid #F2F4F7'
    //     },
    // }
}

export const InboxAvatarGroup = styled(AvatarGroup)(({revdir}: {revdir: boolean}) => ({
    justifyContent: 'flex-end',
    '& .MuiAvatar-root': {
        maxWidth: '24px',
        maxHeight: '24px',
        fontSize: '12px',
    },
    '& .MuiAvatar-root:hover': {
        cursor: 'pointer'
    },
    '& .MuiAvatar-root:last-child': {
        marginLeft: !revdir ? undefined : '-8px'
    },
}));

export const InboxAvatar = styled(CommonAvatar)(({index}: {index: number}) => ({
    backgroundColor: index === 0 ? colors.primary.blue
        : index === 1 ? colors.decorative.lilac
            : index === 2 ? colors.decorative.orange
                : index === 3 ? colors.decorative.violet
                    : 'transparent',
}));
InboxAvatar.defaultProps = {
    size: "d32",
}

export const ActionButton = styled(Button)(() => ({
    borderRadius: "2px",
    padding: "6px 23.5px"
}));

ActionButton.defaultProps = {
    size: 'small',
    variant: 'contained'
}