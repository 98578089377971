import React, {FC, useState} from "react";
import {useMainTranslation} from "../../../../../newShared/hooks/useMainTranslationHooks/useMainTranslation";
import {useSignIn} from "../../hooks/useSignIn";
import {Menu, MenuItem, TextField, Typography} from "@mui/material";
import {SUPPORTED_LANGUAGES} from "../../../../../newShared/constants";
import {useThreeDotsMenu} from "../../../../../newShared/hooks/useThreeDotsMenu";
import {DropdownButton, LoadingButton} from "../../../../../newShared/components/Basic/CommonButtons";
import {Language} from "@mui/icons-material";
import {useRoutes} from "../../hooks/useRoutes";
import colors from "../../../../../newShared/theme/colors";

export const BlockSignInDocs: FC = () => {
    const {t, currentLanguage: {name, code}, handleChooseLanguage} = useMainTranslation('', {keyPrefix: ''});

    const {
        docsLoginByEmail,
        isLoading: {isCheckingPass}
    } = useSignIn();
    const {goToContactUs} = useRoutes();

    const {anchorThreeDotsEl, handleThreeDotsOpen, handleThreeDotsClose} = useThreeDotsMenu();
    const [email, setEmail] = useState<string>('');



    return (
        <>
            <Typography variant={"h1"} textAlign={"center"}>{t('Log in')}</Typography>

            <DropdownButton value={code} sx={{m: '32px auto 0', alignSelf: 'center'}} variant={"text"}
                            icon={<Language/>} open={!!anchorThreeDotsEl} onClick={handleThreeDotsOpen}>
                <Typography variant={"body1"}>{name}</Typography>
            </DropdownButton>
            <Menu open={!!anchorThreeDotsEl} anchorEl={anchorThreeDotsEl} onClose={handleThreeDotsClose}>
                {SUPPORTED_LANGUAGES.map((e) => (
                    <MenuItem value={e.code} key={e.code}
                              sx={{width: anchorThreeDotsEl?.offsetWidth}}
                              onClick={() => {
                                  handleChooseLanguage(e.code);
                                  handleThreeDotsClose();
                              }}>
                        {e.name}
                    </MenuItem>
                ))}
            </Menu>

            <TextField name={'username'} label={t('Email')} value={email} onChange={e => setEmail(e.target.value)}
                       sx={{mt: '16px'}} type={"email"}/>

            <LoadingButton loading={isCheckingPass} onClick={() => docsLoginByEmail(email.trim())}
                           disabled={email.trim() === ''}
                           sx={{mt: '16px', alignSelf: 'flex-start'}}>
                {t('Log In')}
            </LoadingButton>

            <Typography sx={{mt: '16px', fontWeight: 500, fontSize: '18px', lineHeight: '24px'}}>
                {`${t('If you have a problem?')} `}<span onClick={goToContactUs} style={{color: colors.primary.blue, cursor: 'pointer', fontWeight: 600}}>{t('Contact us')}</span>
            </Typography>
        </>
    )
}