import {createRef, FC, MouseEvent, ReactElement, useEffect, useState} from "react";
import {useHistory} from "react-router";
import {useLeftMenu} from "../../hooks/useLeftMenu";
import {Box, Divider, Drawer, List, Menu, MenuItem, Toolbar} from "@mui/material";
import {LeftMenuItem} from "../leftMenuItem";
import {useMedia} from "../../../../../newShared/hooks/useMedia";
import {DropdownButton} from "../../../../../newShared/components/Basic/CommonButtons";
import {useMainTranslation} from "../../../../../newShared/hooks/useMainTranslationHooks/useMainTranslation";
import colors from "../../../../../newShared/theme/colors";

type TProps = {
    customChild?: ReactElement,
    width?: number
}
export const LeftSecondMenu: FC<TProps> = ({children, customChild, width: customWidth}) => {
    const {t, revDir} = useMainTranslation('', {keyPrefix: 'LeftMenu'});
    const history = useHistory();

    const {location: {pathname}} = useHistory();
    const {items: allItems, isOpen: isMainOpen} = useLeftMenu();
    const parent = allItems.find(({path}) => pathname.startsWith(path));
    const child = parent?.child || []

    const {matches_1440Up} = useMedia();

    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const handleOpen = (event: MouseEvent<HTMLElement>) => {setAnchorEl(event.currentTarget);};
    const handleClose = () => {setAnchorEl(null)};

    const [customIsOpen, setCustomIsOpen] = useState<boolean>(false);
    useEffect(() => {
        setCustomIsOpen(false);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [matches_1440Up, isMainOpen]);

    const paperRef = createRef<HTMLDivElement>();
    const [width, setWidth] = useState(customWidth ?? 308);
    const handleClick = () => {
        if ((paperRef?.current?.offsetWidth || 0) >= (customWidth ?? 308) - 1) {
            setWidth(paperRef!.current!.offsetWidth)
        }
    }

    return (
        <Box display={"flex"} width={'100%'} height={'100%'} overflow={"hidden"}
             {...!matches_1440Up ? {p: '14px 20px', flexDirection: "column", alignItems: "flex-start"} : {}}
        >
            {(matches_1440Up || customChild) && (
                <Drawer
                    onClickCapture={() => {setTimeout(handleClick, 1)}}
                    anchor={!revDir ? 'left' : 'right'}
                    sx={{
                        flexShrink: 0,
                        width: width,
                        [`& .MuiDrawer-paper`]: {
                            minWidth: customWidth ?? 308,
                            borderColor: colors.stroke.grey,
                        },
                    }}

                    variant={(matches_1440Up || !customChild) ? "permanent" : "temporary"}
                    {...!(matches_1440Up || !customChild) ? {
                        open: customIsOpen,
                        onClose: () => {setCustomIsOpen(false)},
                        SlideProps: {direction: revDir ? 'left' : 'right'},
                    } : {}}
                >
                    <Toolbar />
                    <Box overflow={'auto'} ref={paperRef}>
                        <List>
                            {allItems
                                .filter(e => pathname.startsWith(e.path))
                                .map((item) => (
                                    <LeftMenuItem key={item.title + ' second title'} item={item} secondTitle/>
                                ))}
                        </List>
                        <Divider variant={"middle"} color={colors.stroke.grey}/>
                        {!customChild ? (
                            <List>
                                {child.map((item) => <LeftMenuItem key={item.title} item={item} second/>)}
                            </List>
                        ) : (
                            customChild
                        )}
                    </Box>
                </Drawer>
            )}

            {!matches_1440Up && (
                <>
                    {parent && (
                        <DropdownButton open={Boolean(anchorEl)}
                                        onClick={!customChild ? handleOpen : () => {setCustomIsOpen(true)}}
                                        variant={"text"}
                                        disableTouchRipple disableRipple disableElevation disableFocusRipple
                                        icon={parent.icon ? <parent.icon/> : undefined}
                                        sx={{
                                            '&, *': {
                                                color: colors.text.blue + ' !important',
                                                fontWeight: 700 + ' !important',
                                                fontSize: '16px !important',
                                                lineHeight: '20px !important',
                                            },
                                            '& .svg-fill path, & .svg-fill circle': {
                                                fill: colors.text.blue,
                                            },
                                            '& .svg-stroke path, & .svg-stroke circle': {
                                                stroke: colors.text.blue,
                                            },
                                            padding: '5px',
                                            marginBottom: '10px'
                                        }}
                        >
                            {t(parent.title)}
                        </DropdownButton>
                    )}
                    <Menu
                        anchorEl={anchorEl}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'right',
                        }}
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'right',
                        }}
                        open={Boolean(anchorEl)}
                        onClose={handleClose}
                    >
                        {child.map(item => (
                            <MenuItem key={item.title} onClick={() => {
                                handleClose();
                                history.push(item.path);
                            }}>
                                {t(item.title)}
                            </MenuItem>
                        ))}
                    </Menu>
                </>
            )}

            <Box width={"100%"} height={'100%'} overflow={"hidden"}
                 p={matches_1440Up ? '24px 20px' : '14px 0'}>
                {children}
            </Box>
        </Box>
    )
}