//Core
import React, {FC, useEffect} from 'react';
import {Routes} from "./routes";

//Layout
//Styled
import {BodyContainer} from "./components/styled"
import {Snack} from "../snack";

import {SnackbarProvider} from "notistack";
import {Box, ThemeProvider} from "@mui/material";
import {useMainTranslationHookInit} from "../../../newShared/hooks/useMainTranslationHooks/useInit";
import {
    useWorkspacesAndOrganizations
} from "../../authWorkspacesCookies/workspaces/hooks/useWorkspacesAndOrganizations";
import {FlexStart} from '../../../newShared/components/Layouts';
import {Spinner} from "../../../newShared/components/Spinner";
import {useSideBar} from "./hooks/useSideBar";
import {TopBar} from "../topBar";
import {LeftMenu} from "../leftMenu";
import colors from "../../../newShared/theme/colors";
import {MessageDialog} from "../MessageDialog";
import {useLocation} from "react-router";
import {NEXT_PATH_LOCAL_STORAGE} from "../../../newShared/constants";
import {useHistory} from "react-router-dom";


export const Shell: FC = () => {
    const history = useHistory();
    const isHiddenBar = useSideBar();
    const {revDir, themeWithLocale} = useMainTranslationHookInit();

    //when workspace or organization is not selected - disabling  buttons and paths user can't go without selection
    const {isLoadingCookieData, isSignedIn} = useWorkspacesAndOrganizations();

    const {search} = useLocation();

    useEffect(() => {
        //     ?nextPath=/documents/myDocuments/300da4f4-39f0-4d56-a5d0-88b17beae5f8
        if(search){
            console.log(`search`, search, isSignedIn);
            const nextPath = search.split('nextPath='); // ['?', 'myDocs/123123123']
            if(nextPath.length === 2 && nextPath[1]?.length > 0){
                localStorage.setItem(NEXT_PATH_LOCAL_STORAGE, nextPath[1]);
            }
        }
        //eslint-disable-next-line
    }, [search]);

    useEffect(() => {
        if(isSignedIn){
            const nextPath = localStorage.getItem(NEXT_PATH_LOCAL_STORAGE);
            if(nextPath) {
                history.push(nextPath);
                localStorage.removeItem(NEXT_PATH_LOCAL_STORAGE);
            }
        }
        //eslint-disable-next-line
    }, [isSignedIn]);

    return (
        <ThemeProvider theme={themeWithLocale}>
            <SnackbarProvider anchorOrigin={{ vertical: 'bottom', horizontal: !revDir ? 'right' : 'left' }} preventDuplicate>
                <FlexStart w={"100vw"} h={"100vh"} of={"hidden"} d={'column'}>
                    <TopBar isHidden={isHiddenBar}/>
                    <BodyContainer w={'100%'}>
                        <Snack/>
                        {isSignedIn && <LeftMenu/>}
                        <Box overflow={"hidden"} height={'100%'} width={'100%'} bgcolor={colors.backgrounds.white}>
                            {!isLoadingCookieData ? <Routes/> : <Spinner />}
                            {/*{(isSignedIn) && <SubscriptionHandler />}*/}
                            <MessageDialog/>
                            {/*<DocViewerDialog/>*/}
                        </Box>
                    </BodyContainer>
                </FlexStart>
            </SnackbarProvider>
        </ThemeProvider>
    )
}
