import {createSelector, createSlice} from "@reduxjs/toolkit";
import {AppState} from "../../../redux/rootReducer";
import {TEditorComponent, TVariable} from "../types";

export type TEditorSlice = {
    isOpen: boolean;
    type: 'new' | 'edit' | 'view' | string;

    initialContent: TEditorComponent[];
    initialVariables: TVariable[];
    initialDocumentTitle: string;

    isLoading: {
        isCreating: boolean;
        isUpdating: boolean;
        isDeleting: boolean;
        isFetching: boolean;
    };
}

const initialState: TEditorSlice = {
    isOpen: false,
    type: 'new',

    initialContent: [],
    initialVariables: [],
    initialDocumentTitle: '',

    isLoading: {
        isCreating: false,
        isUpdating: false,
        isDeleting: false,
        isFetching: false,
    },
}

const Slice = createSlice({
    name: 'editor',
    initialState,
    reducers: {
        cleanAction(slice: TEditorSlice) {
            slice.isOpen = initialState.isOpen;
            slice.type = initialState.type;
        },

        setEditorDialogAction(slice: TEditorSlice, {payload}: {payload: Partial<TEditorSlice>}) {
            if (payload.isOpen !== undefined) slice.isOpen = payload.isOpen;
            if (payload.type !== undefined) slice.type = payload.type;
            if (payload.initialContent !== undefined) slice.initialContent = payload.initialContent;
            if (payload.isLoading !== undefined) slice.isLoading = payload.isLoading;
            if (payload.initialDocumentTitle !== undefined) slice.initialDocumentTitle = payload.initialDocumentTitle;
            if (payload.initialVariables !== undefined) slice.initialVariables = payload.initialVariables;
        },
    },
    // extraReducers: builder => {
    //   builder
    //       .addCase(createReportAction.pending, (slice: TEditorSlice) => {
    //           slice.newReport.isLoading = true;
    //       })
    //       .addCase(createReportAction.rejected, (slice: TEditorSlice) => {
    //           slice.newReport.isLoading = false;
    //       })
    //       .addCase(createReportAction.fulfilled, (slice: TEditorSlice, {payload}) => {
    //           slice.newReport.isLoading = false;
    //           slice.newReport.reportId = payload.resp.id;
    //       })
    //
    // }
});

export const editorReducer = Slice.reducer;

export const {
    cleanAction,
    setEditorDialogAction,
} = Slice.actions;

const selectSelf = (state: AppState): TEditorSlice => state.editor;

export const editorFullSelector = createSelector(selectSelf, state => state);
