import React, {FC} from "react";
import {IStatusProps} from "../../../types";
// import {ManageActorsDialogMain} from "./components/main";
import {ManageActorsDialogStaff, TManageActorsDialogNormalProps} from "./components/staff";
// import {ManageActorsDialogAdmin} from "./components/admin";


export const ManageActorsDialog: FC<TManageActorsDialogNormalProps & {status: IStatusProps["status"]}> = ({status, ...props}) => {
    // if (status.isMain) return (
    //     <ManageActorsDialogMain {...props}/>
    // )

    if (status.isStaff || status.isPortal) return (
        <ManageActorsDialogStaff {...props} isStaff/>
    )

    // if (status.isAdmin) return (
    //     <ManageActorsDialogAdmin {...props}/>
    // )

    return null
}