import {
    APPROVAL_STATUS,
    APPROVED_STATUS,
    COMPLETED_STATUS,
    DRAFT_STATUS,
    IN_PROGRESS_STATUS,
    PENDING_STATUS,
    REJECTED_STATUS,
    REMARKS_STATUS,
    SHARED_DRAFT_STATUS,
    SIGNING_STATUS,
    SKIPPED_STATUS,
    TDocumentExecutorStatus,
    TDocumentStatus,
    TERMINATED_STATUS
} from "./types";
import colors from "../../../newShared/theme/colors";
import {NewDocumentModel, ShortDocumentModel} from "../../../newShared/GQLTypes";

export const getStatusColor = (status?: TDocumentStatus | TDocumentExecutorStatus): string => {

    switch (status) {
        case DRAFT_STATUS: return colors.text.blue;
        case SHARED_DRAFT_STATUS: return colors.text.blue;
        case APPROVAL_STATUS: return colors.decorative.additional;
        case REMARKS_STATUS: return colors.text.warning;
        case SIGNING_STATUS: return colors.decorative.additional_3;
        case COMPLETED_STATUS: return colors.text.success;
        case TERMINATED_STATUS: return colors.decorative.orange;
        //
        case PENDING_STATUS: return colors.primary.blue_dark;
        case IN_PROGRESS_STATUS: return colors.decorative.violet;
        case REJECTED_STATUS: return colors.warningActions.red;
        case APPROVED_STATUS: return colors.decorative.green_dark;
        case SKIPPED_STATUS: return colors.text.grey_dark;
        default: return colors.text.blue;
    }
}

export const convertFullDocumentToSmallDocument = (fullDoc: NewDocumentModel): ShortDocumentModel => {
    return {
        id: fullDoc.id,
        documentId: fullDoc.documentId,
        workspaceId: fullDoc.workspaceId,
        type:     fullDoc.type,
        name:     fullDoc.name,
        folderId: fullDoc.folderId,
        status:   fullDoc.status,
        origin:  fullDoc.origin,
        version:  fullDoc.version,
        latest:   fullDoc.latest,
        createdDate: fullDoc.createdDate,
        tags:     fullDoc.tags,
        lastModified: fullDoc.lastModified,
        recipients: fullDoc.recipients,
        generatedFileId: fullDoc.generatedFileId,
        workspaceName: fullDoc.workspaceName,
        sender: fullDoc.sender,

        currentStepEndDate: fullDoc.currentStepEndDate,
        deletedDate: fullDoc.deletedDate,
    }
}