import {useDispatch, useSelector} from "react-redux";
import {clearList, deselectDoc, documents, loadings, pageInfo, selectedDocument} from "../../store/slice";
import {useSetBreadcrumbs} from "../../../../barsEnvironment/breadcrumbs/hooks/useBreadcrumbs";
import {useMainTranslation} from "../../../../../newShared/hooks/useMainTranslationHooks/useMainTranslation";
import {DEFAULT_PAGING, DOCUMENTS_ROOT, SHARED_DOCUMENTS_PATH} from "../../constants";
import {useScrollPagination} from "../../../../../newShared/hooks/useScrollPagination";
import {TShortDocument} from "../../types";
import {
    ApproveApproveStageByStageId,
    ApproveSignStageByStageId,
    FillFillableFieldOnSignStage,
    GetFullDocumentWithExecutorForDocs,
    GetSharedShortDocumentsPagingDocs,
    RejectApproveStageByStageId,
    RejectSignStageByStageId,
    UpdateFullDocument
} from "../../store/actions";
import {
    TEditorActorsConfigFromDB,
    TEditorElementData,
    TUpdateDataInFillableFieldEventData
} from "../../../../../newShared/components/editorUnion/types";
import {NewDocDataVariableModel} from "../../../../../newShared/GQLTypes";
import {
    UseManageWorkspacesAndOrganizations
} from "../../../../authWorkspacesCookies/workspaces/hooks/useManageWorkspacesAndOrganizations";
import {useSnack} from "../../../../barsEnvironment/snack/hooks/useSnack";
import {createEventUpdateDataInFillableField} from "../../../../../newShared/components/editorUnion/helpers";
import {useEffect} from "react";
import {useParams} from "react-router-dom";
import {keyConfig} from "../../../../../newShared/components/genericFilter/types";
import {getDocsSharedDocumentsAutocompleteWorkspaceNames} from "../../api";

export const useSharedDocs = () => {
    //root
    const dispatch = useDispatch();
    const {t: tMenu} = useMainTranslation('', {keyPrefix: 'LeftMenu'});
    const snack = useSnack();

    //selectors
    const documentsList = useSelector(documents);
    const _pageInfo = useSelector(pageInfo);
    const _loadings = useSelector(loadings);
    const _selectedDoc = useSelector(selectedDocument);
    const {id} = useParams<{id: string}>();

    //effects
    useSetBreadcrumbs([
        {
            title: tMenu('Documents'),
            path: DOCUMENTS_ROOT
        },
        {
            title: tMenu('Shared documents'),
            path: SHARED_DOCUMENTS_PATH
        },
    ]);

    useEffect(() => {
        if(id){
            dispatch(GetFullDocumentWithExecutorForDocs(id));
        }
        //eslint-disable-next-line
    }, []);

    //clear list
    const handleCleanData = () => {
        dispatch(clearList());
    }

    //table
    const {bottomLoader, onScroll, scrollRef} = useScrollPagination({
        getData: ({page, count}) => {
            dispatch(GetSharedShortDocumentsPagingDocs({page, count: DEFAULT_PAGING}))
        },
        arrayLength: documentsList.length,
        isLoading: _loadings.isLoadingList,
        pageInfo: _pageInfo,
        cleanData: handleCleanData,
        // controller: controller,
        // isOnTop: true,
        // isNoMoreThanTotal: true,
        customDependencies: [],
        is0PageAddictive: false,
        disableInitFetch: false,
        // disableClearOnUnmount: true
    })

    //actions
    const handleRowClick = (e: React.MouseEvent<HTMLTableRowElement, MouseEvent>, file: TShortDocument) => {
        if(e.detail === 2 && file){
            //doubleClick
            dispatch(GetFullDocumentWithExecutorForDocs(file.documentId ?? ''))
        }
    }

    const openRecipientsDialog = (recipients: TShortDocument["recipients"]) => {

    }

    //Filters
    const lastModifiedFilter: keyConfig<string> = {
        type: 'date',
        key: 'lastModified',
        name: ('Last modified'),
        default: true,
    }

    const workspaceConfig: keyConfig<{workspaceId: string, workspaceName: string}> = {
        type: 'filter',
        key: 'workspaceId',
        name: ('Workspace name'),
        fetchOptions: getDocsSharedDocumentsAutocompleteWorkspaceNames,
        getOptionLabel: option => option.workspaceName,
        getFilterValue: option => option.workspaceId,
        isOptionEqual: (option, value) => option.workspaceId === value.workspaceId,
        isOptionEqualToSearch: (a, search) => a.workspaceId.trim().toLowerCase().includes(search.trim().toLowerCase()),
        default: true
    }


    const docId = _selectedDoc?.document?.documentId
    return{
        docs: documentsList,
        isLoading: _loadings.isLoadingList,
        bottomLoader,
        onScroll,
        scrollRef,
        pageInfo: _pageInfo,
        hasMore: _pageInfo.total > ((_pageInfo.page + 1) * _pageInfo.count),
        total: !documentsList.length ? 'loading' : _pageInfo.total,
        handleRowClick,
        openRecipientsDialog,
        isNoDocs: _loadings.isLoadingList ? false : !_pageInfo.total,
        selectedDocument: _selectedDoc,
        loadings: _loadings,
        actions: {
            handleCloseEditor: () => {
                dispatch(deselectDoc())
            },
            onRejectSignStage: (stageId: string) => {
                docId && dispatch(RejectSignStageByStageId({documentId: docId, stageId}))
            },
            onRejectApproveStage: (stageId: string) => {
                docId && dispatch(RejectApproveStageByStageId({documentId: docId, stageId}))
            },
            onApproveApproveStage: (stageId: string) => {
                docId && dispatch(ApproveApproveStageByStageId({documentId: docId, stageId}))
            },
            onApproveSignStage: (stageId: string) => {
                docId && dispatch(ApproveSignStageByStageId({documentId: docId, stageId}))
            },
            onFillFields: (stepId: string, data: TUpdateDataInFillableFieldEventData) => {
                const onReject = () => {
                    createEventUpdateDataInFillableField(data.map(e => ({...e, value: ''})));
                }
                docId && dispatch(FillFillableFieldOnSignStage({stageId: stepId, values: data, onReject, documentId: docId}))
            },
            handleSave: (data: TEditorElementData<{}>[], variables: NewDocDataVariableModel[], docTitle: string, actors: TEditorActorsConfigFromDB) => {
                console.log("onSave: \n", {data, variables, docTitle, actors})
                if(_selectedDoc.document) {
                    dispatch(UpdateFullDocument({
                        documentId: _selectedDoc.document.documentId ?? '',
                        name: docTitle,
                        data: {
                            editor: data,
                            variables,
                            pdfFileId: _selectedDoc.document.data?.pdfFileId || null,
                        },
                        recipients: actors.recipients,
                        approvers: actors.approvers,
                        editors: actors.editors
                    }))
                }
            }
        },
        currentEmail: UseManageWorkspacesAndOrganizations().user?.email || '',
        snack,
        filters: {
            configs: [workspaceConfig, lastModifiedFilter],
            handleFetch: (search: string, filters: Record<string, string[]>, signal: AbortSignal) => {
                dispatch(clearList());
                dispatch(GetSharedShortDocumentsPagingDocs({page: 0, count: DEFAULT_PAGING, filters: {...filters, search: [search]}}))
            }
        }
    }
}