import {TDocumentStatus, TShortDocument} from "../../types";
import {FC} from "react";
import {useMainTranslation} from "../../../../../newShared/hooks/useMainTranslationHooks/useMainTranslation";
import * as S from './styled';
import {TableCell, TableRow, Typography} from "@mui/material";
import {getStatusColor} from "../../helpers";
import {parseDateAuto} from "../../../../../newShared/utils/dateTools";

// import {ReactComponent as DownloadIcon} from "../../../../../newShared/images/documents/downloads-folder.svg";


interface DocumentTableRowProps {
    file: TShortDocument,
    openFile: (e: React.MouseEvent<HTMLTableRowElement, MouseEvent>, file: TShortDocument) => void,
    openRecipientsDialog: (recipients: TShortDocument["recipients"]) => void,
}
export const SharedDocumentTableRow: FC<DocumentTableRowProps> = ({file, openRecipientsDialog, openFile}) => {
    const {revDir} = useMainTranslation('', {keyPrefix: 'pathDocuments.ExplorerPage'});

    const statusColor = getStatusColor(file.status as TDocumentStatus);

    return(
        <TableRow hover onClick={(e) => openFile(e, file)}>
            <S.DocNameTableCell>
                <Typography variant={'*bodyText2'} noWrap>{file.name}</Typography>
            </S.DocNameTableCell>
            <TableCell>
                <Typography variant={'*bodyText2'} noWrap>{file.sender ?? '-'}</Typography>
            </TableCell>
            <TableCell>
                <Typography variant={'*bodyText2'} noWrap>{file.workspaceName ?? '-'}</Typography>
            </TableCell>
            <S.DocLastModifiedTableCell>
                <Typography variant={'*bodyText2'} noWrap>{parseDateAuto(file.lastModified ?? file.createdDate)}</Typography>
            </S.DocLastModifiedTableCell>

            <S.DocRecipientsTableCell align={"center"}>
                {!!file.recipients?.length && (
                    <S.DocumentAvatarGroup onClick={() => openRecipientsDialog(file.recipients)}
                                           revDir={revDir} max={4} total={file.recipients.length} >
                        {file.recipients.map((e, index) => (
                            <S.DocumentAvatar key={e.actor?.email} index={index} name={`${e.actor?.firstName || e.actor?.email} ${e.actor?.lastName || e.actor?.email}`} alt={e.actor?.email ?? ''} />
                        ))}
                    </S.DocumentAvatarGroup>
                )}
            </S.DocRecipientsTableCell>

            <S.DocStatusTableCell>
                <S.StatusTableCellContainer>
                    <S.StatusRoundBox color={statusColor} revDir={revDir}/>
                    {/*<S.StatusTypography color={statusColor}>{file?.status?.toUpperCase()}</S.StatusTypography>*/}
                    <Typography variant={'*bodyText2'} color={statusColor} >{file?.status?.toUpperCase()}</Typography>
                </S.StatusTableCellContainer>
            </S.DocStatusTableCell>
        </TableRow>
    )
}
